/** @format */

import { LockClosedIcon } from "@heroicons/react/24/outline";
import { ShieldCheckIcon } from "@heroicons/react/24/solid";
import { FLEET_TYPES, STATE_CODES } from "@roadflex/constants";
import { usePlatformDetect } from "@roadflex/react-hooks-www";
import {
  AdditionalBusinessInfoType,
  AdditionalBusinessInfoValues,
} from "@roadflex/types";
import { fuelFormatter } from "@roadflex/web-lib";
import classNames from "classnames";
import { FormikProps } from "formik";
import { forwardRef, LegacyRef, Ref } from "react";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../buttons";
import { Select } from "../dropdowns";
import { TextInput } from "../inputs";
import { OnboardBannerComponent } from "./common-banner-component";

interface AdditionalBusinessInfoUIProps
  extends FormikProps<AdditionalBusinessInfoValues> {
  isSubmittingOnboardStatus: boolean;
  additionalBusinessInfo: AdditionalBusinessInfoType;
  onPreviousStep: () => void;
}

const AdditionalBusinessInfoUI = forwardRef(
  (
    {
      values,
      handleChange,
      handleSubmit,
      handleBlur,
      isSubmitting,
      errors,
      touched,
      isSubmittingOnboardStatus,
      setFieldValue,
      setFieldTouched,
      dirty,
      onPreviousStep,
      ...rest
    }: AdditionalBusinessInfoUIProps,
    autoCompleteRef: unknown,
  ) => {
    const { businessAutoCompleteRef = null, shippingAutoCompleteRef = null } = (
      autoCompleteRef as { current: unknown }
    )?.current as {
      businessAutoCompleteRef: LegacyRef<HTMLInputElement>;
      shippingAutoCompleteRef: LegacyRef<HTMLInputElement>;
    };
    const { isMobile } = usePlatformDetect();
    return (
      <div
        className={classNames(
          isMobile() ? "flex-col" : "flex-row",
          "flex w-full h-full min-h-screen",
        )}
      >
        <OnboardBannerComponent
          header="Tell us about your business (2 of 2)"
          subText="We need some more details to verify your business."
        />
        <div className="flex flex-col w-full px-4 py-8 md:p-16">
          <form className="flex flex-col w-full" onSubmit={handleSubmit}>
            <div className="relative w-full">
              <TextInput
                label="Street Address* (PO Box not allowed)"
                name="businessAddress.streetAddress"
                ref={
                  businessAutoCompleteRef as unknown as Ref<HTMLInputElement>
                }
                value={values?.businessAddress?.streetAddress}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={30}
                error={
                  touched?.businessAddress?.streetAddress &&
                  Boolean(errors?.businessAddress?.streetAddress)
                }
                errorMessage={errors?.businessAddress?.streetAddress}
                touched={touched?.businessAddress?.streetAddress}
                variant="responsive"
                informationToolTip="A valid business address is required. Any typos or wrong address can lead to lengthened review times."
              ></TextInput>
            </div>
            <div className="relative w-full mt-2">
              <TextInput
                label="Street Address 2"
                name="businessAddress.streetDetails"
                value={values?.businessAddress?.streetDetails}
                onChange={handleChange}
                onBlur={handleBlur}
                maxLength={200}
                error={
                  touched?.businessAddress?.streetDetails &&
                  Boolean(errors?.businessAddress?.streetDetails)
                }
                errorMessage={errors?.businessAddress?.streetDetails}
                touched={touched?.businessAddress?.streetDetails}
                variant="responsive"
              ></TextInput>
            </div>

            <div className="flex flex-col sm:flex-row">
              <div className="relative w-full mt-2">
                <TextInput
                  label="City"
                  name="businessAddress.city"
                  value={values?.businessAddress?.city}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={15}
                  error={
                    touched?.businessAddress?.city &&
                    Boolean(errors?.businessAddress?.city)
                  }
                  errorMessage={errors?.businessAddress?.city}
                  touched={touched?.businessAddress?.city}
                  variant="responsive"
                  required
                ></TextInput>
              </div>
              <div className="relative w-full mt-2 sm:ml-4">
                <Select
                  label="State"
                  value={values?.businessAddress?.state}
                  options={[
                    {
                      label: "-- Select a State--",
                      value: "",
                      disabled: true,
                    },
                  ].concat(
                    STATE_CODES.map((m) => {
                      return {
                        label: `${m.label} (${m.value})`,
                        value: m.value,
                        disabled: false,
                      };
                    }),
                  )}
                  optionLabel="label"
                  optionValue="value"
                  onChange={(e) =>
                    setFieldValue("businessAddress.state", e.value)
                  }
                  onBlur={() => setFieldTouched("businessAddress.state", true)}
                  placeholder="Select"
                  touched={touched?.businessAddress?.state}
                  error={
                    touched?.businessAddress?.state &&
                    Boolean(errors?.businessAddress?.state)
                  }
                  errorMessage={errors?.businessAddress?.state}
                  required
                  variant="responsive"
                ></Select>
              </div>

              <div className="relative w-full mt-2 sm:ml-4">
                <TextInput
                  label="Zip"
                  name="businessAddress.zip"
                  value={values?.businessAddress?.zip}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  maxLength={5}
                  error={
                    touched?.businessAddress?.zip &&
                    Boolean(errors?.businessAddress?.zip)
                  }
                  errorMessage={errors?.businessAddress?.zip}
                  touched={touched?.businessAddress?.zip}
                  variant="responsive"
                  required
                ></TextInput>
              </div>
            </div>

            <div className="relative w-full mt-2">
              <TextInput
                label="Annual Business Revenue (Approximate)"
                name="annualBusinessRevenue"
                value={values.annualBusinessRevenue}
                onChange={(event) =>
                  setFieldValue(
                    "annualBusinessRevenue",
                    fuelFormatter(event?.target?.value),
                  )
                }
                onBlur={handleBlur}
                error={
                  touched.annualBusinessRevenue &&
                  Boolean(errors.annualBusinessRevenue)
                }
                errorMessage={errors.annualBusinessRevenue as string}
                touched={touched.annualBusinessRevenue as boolean}
                variant="responsive"
                required
              ></TextInput>
            </div>

            <div className="relative w-full mt-2">
              <TextInput
                label="Total Fuel and Maintenance spend per week"
                name="fuel"
                value={values.fuel}
                onChange={(event) =>
                  setFieldValue("fuel", fuelFormatter(event?.target?.value))
                }
                onBlur={handleBlur}
                error={touched.fuel && Boolean(errors.fuel)}
                errorMessage={errors.fuel as string}
                touched={touched.fuel as boolean}
                variant="responsive"
                required
              ></TextInput>
            </div>

            <div className="relative w-full mt-2">
              <TextInput
                label="Total Number of Vehicles"
                name="fleetNum"
                value={values.fleetNum}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.fleetNum && Boolean(errors.fleetNum)}
                errorMessage={errors.fleetNum}
                touched={touched.fleetNum}
                variant="responsive"
                required
              ></TextInput>
            </div>

            <div className="relative w-full mt-2">
              <Select
                label="Type of Fleet"
                required
                onChange={(e) => setFieldValue("fleetType", e.value)}
                onBlur={() => setFieldTouched("fleetType", true)}
                value={values.fleetType}
                name="fleetType"
                options={[
                  {
                    label: "-- Select an option --",
                    value: "",
                    disabled: true,
                  },
                ].concat(
                  FLEET_TYPES.map((fT) => {
                    return {
                      label: fT.label,
                      value: fT.value,
                      disabled: false,
                    };
                  }),
                )}
                optionLabel="label"
                optionValue="value"
                placeholder="-- Select an option --"
                touched={touched.fleetType}
                error={touched.fleetType && Boolean(errors.fleetType)}
                errorMessage={errors.fleetType}
                variant="responsive"
              ></Select>
            </div>
            <div className="mt-2 text-xs md:text-sm">*Required Fields</div>

            {/* <div className="mb-5 text-sm text-white ">
          Preferred delivery method
        </div>

        <AddressDeliveryUI
          {...{
            values,
            handleChange,
            handleSubmit,
            errors,
            touched,
            isSubmitting,
            handleBlur,
            ...rest,
          }}
        /> */}
            <div className="mt-6">
              <Button
                type={ButtonType.Submit}
                variant={ButtonVariant.Orange}
                className="w-full"
                disabled={isSubmitting || isSubmittingOnboardStatus}
                loading={isSubmitting || isSubmittingOnboardStatus}
              >
                <LockClosedIcon className="w-4 h-4 mr-1"></LockClosedIcon>
                {dirty ? `Save` : `Next`}
              </Button>
            </div>
            <div>
              <div className="flex items-center justify-center mt-1 text-xs text-center md:text-sm">
                <ShieldCheckIcon className="w-4 h-4 mr-1"></ShieldCheckIcon>
                We securely store and encrypt your personal information
              </div>
              <Button
                type={ButtonType.Button}
                variant={ButtonVariant.Transparent}
                className="pl-0 "
                size={ButtonSize.Small}
                onClick={onPreviousStep}
              >
                &#60;&#60; Go back
              </Button>
            </div>
          </form>
        </div>
      </div>
    );
  },
);

export default AdditionalBusinessInfoUI;
