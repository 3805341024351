/** @format */

import { useQuery } from "@apollo/client";
import { ENUM_ELD_ORG_NAME } from "@prisma/client";
import { ELD_SYSTEMS } from "@roadflex/constants";
import { ELD_CONNECTED } from "@roadflex/graphql";
import { useGeoTabGetDevices, useReadVehicles } from "@roadflex/react-hooks";
import {
  EldDevices,
  MapType,
  TripDetails,
  UserAuthType,
} from "@roadflex/types";
import { useEffect, useState } from "react";
import { Loader } from "../../loader";
import Redirect from "../../redirect/redirect";
import { EldDetailsPageUI } from "./eld-system-detail-page.ui";

interface EldDetailsProps {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
  name: string;
  refetch: () => void;
}

export const EldDetailsPage = ({
  readCurrentUserAuth,
  loading,
  name,
  refetch,
}: EldDetailsProps) => {
  const [geoTabDevices, setGeoTabDevices] = useState<EldDevices[]>([]);
  const [samsaraDevices, setSamsaraDevices] = useState<EldDevices[]>([]);
  const [azugaDevices, setAzugaDevices] = useState<EldDevices[]>([]);
  const [motiveDevices, setMotiveDevices] = useState<EldDevices[]>([]);
  const [uthDevices, setUthDevices] = useState<EldDevices[]>([]);
  const [fleetUpDevices, setFleetUpDevices] = useState<EldDevices[]>([]);
  const [verizonDevices, setVerizonDevices] = useState<EldDevices[]>([]);
  const [bouncieDevices, setBouncieDevices] = useState<EldDevices[]>([]);
  const [nexTraqDevices, setNexTraqDevices] = useState<EldDevices[]>([]);
  const [openGeoTabDeviceTripsModal, setOpenGeoTabDeviceTripsModal] =
    useState(false);
  const [openGMapsModal, setOpenGMapsModal] = useState(false);
  const [openEditGeoTabDeviceModal, setOpenEditGeoTabDeviceModal] =
    useState(false);
  const [isInitLoad, setInitLoad] = useState(true);

  const { getVehiclesData, getVehiclesLoading } = useReadVehicles([]);

  // Hardcoding values for the POC, these will be removed after testing
  const initialMapValuesObj = {
    currentStateDuration: "",
    deviceId: "",
    deviceName: "",
    driver: "",
    isDriving: "",
    isHistoricLastDriver: "",
    dateTime: "",
  };

  const todayDateObj = new Date();
  todayDateObj.setHours(0, 0, 0, 0);
  const oneWeekAgoDateObj = new Date(Date.now() - 1 * 24 * 60 * 60 * 1000);
  todayDateObj.setHours(0, 0, 0, 0);

  const initialTripDetailsObj = {
    dates: [oneWeekAgoDateObj, todayDateObj],
    fromVersion: "0000000000000000",
    resultsLimit: 500,
    deviceId: "",
    deviceName: "",
  };

  const [mapValues, setMapValues] = useState<MapType>({
    ...initialMapValuesObj,
  });
  const [tripDetailValues, setTripDetailValues] = useState<TripDetails>({
    ...initialTripDetailsObj,
  });
  const [database, setDatabase] = useState("");
  const [orgName, setOrgName] = useState(name?.toUpperCase() || "");
  const [editGeoTabDeviceValues, setEditGeoTabDeviceValues] = useState<{
    geoTabDeviceId: string;
    geoTabDeviceName: string;
    vehicleId: string;
  }>({
    geoTabDeviceId: "",
    geoTabDeviceName: "",
    vehicleId: "",
  });

  const { data: eldConnected } = useQuery<{
    isEldConnected: {
      isConnected: boolean;
      orgName: ENUM_ELD_ORG_NAME;
    };
  }>(ELD_CONNECTED, {
    fetchPolicy: "no-cache",
  });

  if (
    (eldConnected && !eldConnected.isEldConnected.isConnected) ||
    (eldConnected && orgName !== eldConnected.isEldConnected.orgName)
  ) {
    window.location.href = "/eld-systems";
  }

  const {
    handleSubmit: geoTabGetDevicesHandleSubmit,
    ...geoTabGetDevicesFormikValues
  } = useGeoTabGetDevices({
    initialValues: {
      database: "",
      orgName: orgName,
    },

    onSubmit(res, err) {
      if (err) {
        console.error(err.message);
        return;
      }
      // console.log(res, err);
      if (orgName === ELD_SYSTEMS.GEOTAB) {
        setGeoTabDevices(res?.getEldDevices || []);
      } else if (orgName === ELD_SYSTEMS.SAMSARA) {
        setSamsaraDevices(res?.getEldDevices || []);
      } else if (orgName === ELD_SYSTEMS.AZUGA) {
        setAzugaDevices(res?.getEldDevices || []);
      } else if (orgName === ELD_SYSTEMS.MOTIVE) {
        setMotiveDevices(res?.getEldDevices || []);
      } else if (orgName === ELD_SYSTEMS.UTH) {
        setUthDevices(res?.getEldDevices || []);
      } else if (orgName === ELD_SYSTEMS.FLEETUP) {
        setFleetUpDevices(res?.getEldDevices || []);
      } else if (orgName === ELD_SYSTEMS.VERIZON) {
        setVerizonDevices(res?.getEldDevices || []);
      } else if (orgName === ELD_SYSTEMS.NEXTRAQ) {
        setNexTraqDevices(res?.getEldDevices || []);
      } else if (orgName === ELD_SYSTEMS.BOUNCIE) {
        setBouncieDevices(res?.getEldDevices || []);
      }
      if (isInitLoad) {
        // toast.success("GeoTab Device Data successfully retrieved.");
        setInitLoad(false);
      }
    },
  });

  const setMapData = (rowData: EldDevices) => {
    setMapValues({
      ...initialMapValuesObj,
      deviceId: rowData?.id,
      deviceName: rowData?.deviceName,
    });
    setOpenGMapsModal(true);
  };

  const openGeoTabTripDetailsModal = (rowData: EldDevices) => {
    setTripDetailValues({
      ...initialTripDetailsObj,
      deviceId: rowData.id,
      deviceName: rowData?.deviceName,
    });
    setOpenGeoTabDeviceTripsModal(true);
  };

  const openEditGeoTabDetailsModal = (rowData: EldDevices) => {
    setEditGeoTabDeviceValues({
      geoTabDeviceId: rowData.eldDeviceDetailsId,
      geoTabDeviceName: rowData.id,
      vehicleId: rowData?.fleetId || "",
    });
    setOpenEditGeoTabDeviceModal(true);
  };

  useEffect(() => {
    geoTabGetDevicesHandleSubmit();
  }, []);

  if (loading) {
    return <Loader />;
  }

  const vehiclesList = getVehiclesData?.readVehicles?.vehicles || [];

  if (!Object.keys(ENUM_ELD_ORG_NAME).find((value) => value === orgName)) {
    return <Redirect path="/eld-systems"></Redirect>;
  }

  return (
    <EldDetailsPageUI
      {...{
        geoTabDevices,
        samsaraDevices,
        azugaDevices,
        motiveDevices,
        uthDevices,
        fleetUpDevices,
        verizonDevices,
        nexTraqDevices,
        bouncieDevices,
        openGeoTabDeviceTripsModal,
        setOpenGeoTabDeviceTripsModal,
        openGeoTabTripDetailsModal,
        openGMapsModal,
        setOpenGMapsModal,
        setMapData,
        mapValues,
        setMapValues,
        tripDetailValues,
        orgName,
        setOrgName,
        database,
        openEditGeoTabDeviceModal,
        setOpenEditGeoTabDeviceModal,
        openEditGeoTabDetailsModal,
        editGeoTabDeviceValues,
        setEditGeoTabDeviceValues,
        vehiclesList,
        getVehiclesLoading,
        geoTabGetDevicesForm: {
          handleSubmit: geoTabGetDevicesHandleSubmit,
          ...geoTabGetDevicesFormikValues,
        },
      }}
    />
  );
};
