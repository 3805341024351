/** @format */

import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { ENUM_CUSTOMER_TYPE, ENUM_TIME_ZONE } from "@prisma/client";
import {
  ExtendedTransactionType,
  TransactionType,
  UserAuthType,
} from "@roadflex/types";
import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { Column } from "primereact/column";
import { DataTable, DataTableRowToggleParams } from "primereact/datatable";
import { useState } from "react";
import { Button, ButtonSize, ButtonVariant } from "../../../buttons";
import { dateFilterTemplate } from "../../../datatable";
import {
  dateOnlyTemplate,
  dateTemplate,
} from "../../../datatable/common-templates/common-templates";
import { newPaginationTemplate } from "../../../datatable/pagination-templates/new-pagination-template";
import {
  stateTemplate,
  transactionAmountTemplate,
} from "../../../datatable/transaction-table-templates/table-templates";
import { InfoModal } from "../../../modals";
import ImportTransactionsModal from "./upload-transactions-file-modal";

interface TransactionsImportedUIProps {
  transactionList: ExtendedTransactionType[];
  transactionLoading: boolean;
  transactionRefetch: () => void;
  readCurrentUserAuth: UserAuthType;
  customerType?: ENUM_CUSTOMER_TYPE | undefined;
}

const TransactionsImportedUI = ({
  transactionList,
  transactionLoading,
  transactionRefetch,
  readCurrentUserAuth,
  customerType,
}: TransactionsImportedUIProps) => {
  const router = useRouter();
  const backToTransactionsPanel = () => {
    router.push("/transactions");
  };
  // const { isMobile } = usePlatformDetect();
  const [expandedRows, setExpandedRows] = useState<TransactionType[]>([]);
  const [openImportModal, setOpenImportModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [selectedRecord, setSelectedRecord] = useState<any>(null);

  const onOpenMoreDetails = (details: TransactionType) => {
    setSelectedRecord(details.extraInfo);
    setDetailModal(true);
  };

  const extraDetailsTemplate = (rowData: TransactionType) => {
    return (
      <div>
        {!isEmpty(rowData.extraInfo) && (
          <Button
            variant={ButtonVariant.AppOrange}
            size={ButtonSize.Small}
            onClick={() => onOpenMoreDetails(rowData)}
          >
            Show
          </Button>
        )}
      </div>
    );
  };

  const rowExpansionTemplate = (data: TransactionType) => {
    return (
      <div className="max-w-sm p-4 mx-auto bg-white border border-gray-300 rounded-md shadow-lg">
        <div className="mb-4 font-semibold text-gray-800">
          {data.merchantName}
        </div>
        <div className="flex flex-col gap-2 text-sm text-gray-600"></div>
      </div>
    );
  };
  return (
    <div className="flex-col flex flex-1 m-4 mt-0 overflow-x-hidden md:px-4 md:pb-4 h-full md:min-h-[calc(100vh-96px)]">
      <div className="flex flex-col w-full rounded-md md:my-12">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row">
            <Button
              size={ButtonSize.Small}
              variant={ButtonVariant.GrayOutline}
              onClick={backToTransactionsPanel}
              className="pl-0 ml-[-6px] border-0"
            >
              <span className="flex items-center h-5">
                <ChevronRightIcon
                  className={
                    "-rotate-180 h-5 w-5 transform  ease-in duration-300"
                  }
                  aria-hidden="true"
                />
              </span>
              Back
            </Button>
            <div className="ml-4 text-lg font-semibold">
              Imported Transactions&nbsp;
              <span className="font-normal text-yellow-600">(Enterprise)</span>
            </div>
          </div>
        </div>
      </div>
      <div className="p-4 mt-5 overflow-hidden bg-white rounded-md shadow md:p-8 md:mt-8 step-4">
        <div className="flex items-center justify-between gap-4 md:gap-8">
          <div className="font-semibold text-brown-500 md:text-lg">
            Imported Transactions
          </div>
          <Button
            size={ButtonSize.AppSize}
            variant={ButtonVariant.AppOrange}
            disabled={
              !(
                customerType === ENUM_CUSTOMER_TYPE.PREPAID_ENTERPRISE ||
                customerType === ENUM_CUSTOMER_TYPE.CREDIT_ENTERPRISE
              )
            }
            onClick={() => {
              setOpenImportModal(true);
            }}
            className="whitespace-nowrap"
          >
            Import Transactions
          </Button>
        </div>
        <div className="flex flex-col h-full mt-4">
          <DataTable
            size="small"
            stripedRows
            value={transactionList}
            loading={transactionLoading}
            paginator
            rows={10}
            dataKey="id"
            sortField="date"
            sortOrder={-1}
            paginatorTemplate={newPaginationTemplate}
            responsiveLayout="scroll"
            emptyMessage="No available records"
            style={{ fontFamily: "Inter" }}
            className="!bg-white custom-table !text-brown-500 !border-brown-500"
            rowExpansionTemplate={rowExpansionTemplate}
            expandedRows={expandedRows}
            onRowToggle={(e: DataTableRowToggleParams) =>
              setExpandedRows(e.data as TransactionType[])
            }
          >
            <Column
              filter
              filterElement={dateFilterTemplate}
              filterMenuClassName="custom-filter"
              sortable
              dataType="date"
              filterField="date"
              field="date"
              header={`Date (${readCurrentUserAuth?.accountDisplaySetting?.timeZone})`}
              body={(r) =>
                dateOnlyTemplate(
                  r.transactionDate,
                  readCurrentUserAuth?.accountDisplaySetting?.timeZone,
                )
              }
              showFilterOperator={false}
              showFilterMatchModes={true}
              showAddButton={false}
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
            />
            <Column
              // filter
              sortable
              filterPlaceholder="Search by Name"
              field="merchantName"
              header="Merchant"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
            />
            <Column
              // filter
              sortable
              filterPlaceholder="Search by Location"
              sortField="merchantState"
              header="Location"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={stateTemplate}
            />
            <Column
              // filter
              sortable
              field="transactionAmount"
              header="Amount"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={transactionAmountTemplate}
            />
            <Column
              // filter
              sortable
              field="fuelQuantity"
              header="Fuel Quantity"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
            />
            <Column
              sortable
              dataType="date"
              field="createdAt"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-2 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              header={`Imported Date (${readCurrentUserAuth?.accountDisplaySetting?.timeZone})`}
              body={(r) =>
                dateTemplate(
                  r.createdAt,
                  readCurrentUserAuth?.accountDisplaySetting?.timeZone ||
                    ENUM_TIME_ZONE.USER,
                )
              }
            />
            <Column
              header="Details"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={extraDetailsTemplate}
            />
          </DataTable>
        </div>
      </div>
      {openImportModal && (
        <ImportTransactionsModal
          uploadTransactionsFilePopup={openImportModal}
          setUploadTransactionsFilePopup={setOpenImportModal}
          refetchTransactions={transactionRefetch}
        ></ImportTransactionsModal>
      )}
      {detailModal && (
        <InfoModal
          title="Details"
          body={
            <div className="mt-4 text-base">
              <pre className="leading-5">
                <div className="">
                  {JSON.stringify(selectedRecord, null, 4)}
                </div>
              </pre>
            </div>
          }
          show={detailModal}
          setShow={setDetailModal}
        />
      )}
    </div>
  );
};

export default TransactionsImportedUI;
