/* eslint-disable max-lines-per-function */
/** @format */
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { ENUM_ADDRESS_TYPE } from "@prisma/client";
import {
  appDeliveryAddressSchema,
  appUpdateCardPinSchema,
} from "@roadflex/constants";
import {
  CREATE_AND_UPDATE_DELIVERY_ADDRESS,
  READ_ADDRESSES,
  READ_APP_CARD_DATA,
  REMOVE_DELIVERY_ADDRESS,
  UPDATE_CARD_PIN,
} from "@roadflex/graphql";
import { CardShortened, ExtendedAddressType } from "@roadflex/types";
import { useFormik } from "formik";
import { useState } from "react";

type useUpdateCardPinProps = {
  initialValues: {
    cardId: string;
    pin: string;
  };
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

export const useReadAppCardData = (
  returnPhysical: boolean,
  returnDeliveredOnly: boolean,
  returnVirtual: boolean,
  mode: string,
  cardId?: string,
  cardTags?: string[],
) => {
  const { data, ...rest } = useQuery(READ_APP_CARD_DATA, {
    fetchPolicy: "no-cache",
    variables: {
      data: {
        returnPhysical,
        returnDeliveredOnly,
        returnVirtual,
        mode,
        cardId,
        cardTags,
      },
    },
  });
  const { readAppCardData = null }: { readAppCardData: any | null } =
    data || {};

  return { readAppCardData, ...rest };
};

export function useLazyReadAppCardData(networkId?: string) {
  const [cards, setCards] = useState<CardShortened[]>([]);

  const [cardsListFn, { loading: cardsListLoading }] = useLazyQuery<{
    readAppCardData: {
      cards: CardShortened[];
    };
  }>(READ_APP_CARD_DATA, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (data?.readAppCardData?.cards) {
        const cardsMap = data?.readAppCardData?.cards.map((card) => {
          if (
            card.blockedMerchantsNetworkIds?.find(
              (merchant) => merchant.networkId === networkId,
            )
          ) {
            return { ...card, disabled: true };
          }
          return { ...card, disabled: false };
        });
        setCards(cardsMap);
      }
    },
    notifyOnNetworkStatusChange: true,
  });

  return {
    cardsListFn,
    cards,
    cardsListLoading,
  };
}

export const useUpdateCardPin = ({
  onSubmit,
  initialValues,
}: useUpdateCardPinProps) => {
  const [updateCardPinFn] = useMutation<{
    updateCardPin: {
      code: string;
      message: string;
    };
  }>(UPDATE_CARD_PIN, {});

  const {
    handleChange,
    handleSubmit,
    values,
    isSubmitting,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues
  } = useFormik({
    initialValues,
    validationSchema: appUpdateCardPinSchema,
    onSubmit: async (value) => {
      try {
        const res = await updateCardPinFn({
          variables: {
            data: {
              cardId: value.cardId,
              pin: value.pin.toString(),
            },
          },
        });
        if (res?.data?.updateCardPin) {
          onSubmit(res.data.updateCardPin, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    handleChange,
    handleSubmit,
    values,
    isSubmitting,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues,
  };
};

export const useManageAddresses = (
  addresses: ExtendedAddressType[],
  businessId: string,
  onSubmitError: (message: string) => void,
) => {
  const [showAddressInputs, setShowAddressInputs] = useState(false);
  const [createAndUpdateDeliveryAddressFn] = useMutation(
    CREATE_AND_UPDATE_DELIVERY_ADDRESS,
    {
      refetchQueries: [READ_ADDRESSES],
    },
  );
  const [removeDeliveryAddressFn] = useMutation(REMOVE_DELIVERY_ADDRESS, {
    refetchQueries: [READ_ADDRESSES],
  });

  const formik = useFormik<{
    businessId: string;
    deliveryAddress: ExtendedAddressType;
  }>({
    initialValues: {
      businessId: businessId,
      deliveryAddress: {
        id: "",
        streetAddress: "",
        streetDetails: "",
        state: "",
        city: "",
        zip: "",
        type: ENUM_ADDRESS_TYPE.DELIVERY,
        isPrimary: true,
      },
    },
    validationSchema: appDeliveryAddressSchema,
    onSubmit: async (values, { resetForm }) => {
      const { data } = await createAndUpdateDeliveryAddressFn({
        variables: {
          data: values,
        },
      });
      if (data?.createAndUpdateDeliveryAddress?.code !== "200") {
        onSubmitError(data?.createAndUpdateDeliveryAddress?.message);
        return;
      }
      setShowAddressInputs(false);
      resetForm();
    },
  });

  const selectAddress = async (id: string) => {
    const address = addresses.find((addr) => addr.id === id);
    if (address) {
      // Notify the server about the selected address
      const { data } = await createAndUpdateDeliveryAddressFn({
        variables: {
          data: {
            businessId: businessId,
            deliveryAddress: { ...address, isPrimary: true },
          },
        },
      });

      if (data?.createAndUpdateDeliveryAddress?.code !== "200") {
        onSubmitError(data?.createAndUpdateDeliveryAddress?.message);
      }
    }
  };

  const removeAddress = async (id: string) => {
    const address = addresses.find((addr) => addr.id === id);

    const { data } = await removeDeliveryAddressFn({
      variables: {
        data: {
          businessId: businessId,
          deliveryAddress: address,
        },
      },
    });
    if (data?.removeDeliveryAddress?.code !== "200") {
      onSubmitError(data?.removeDeliveryAddress?.message);
    }
  };

  return {
    addresses,
    selectAddress,
    removeAddress,
    formik,
    setShowAddressInputs,
    showAddressInputs,
  };
};

export default useManageAddresses;
