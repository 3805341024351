/** @format */

import { useMutation } from "@apollo/client";
import {
  DocumentMagnifyingGlassIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import {
  appVehicleFileValidationSchema,
  appVehicleValidationSchema,
} from "@roadflex/constants";
import { CREATE_MULTIPLE_VEHICLES } from "@roadflex/graphql";
import { Parser } from "csv-parse";
import { useFormik } from "formik";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import * as Yup from "yup";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";
import ExportImportModalLoader from "../../loader/export-import-modal-loader";
import { Toast } from "../../toast-message/toast";

export type UploadVehiclesFileProps = {
  uploadVehiclesFilePopup: boolean;
  setUploadVehiclesFilePopup: (uploadVehiclesFilePopup: boolean) => void;
  refetchVehicles: () => void;
};
export default function UploadVehiclesFile({
  uploadVehiclesFilePopup,
  setUploadVehiclesFilePopup,
  refetchVehicles,
}: UploadVehiclesFileProps) {
  const [createMultipleVehicles] = useMutation(CREATE_MULTIPLE_VEHICLES);

  interface CSVRow {
    fleetName: string;
    licensePlate: string;
    make: string;
    model: string;
    tankCapacity: number;
    vin: string;
    year: string;
    error?: string;
  }

  // Define a mapping between CSV column names and validation schema names
  const columnMapping: Record<string, keyof CSVRow> = {
    "Vehicle Name": "fleetName",
    VIN: "vin",
    Make: "make",
    Model: "model",
    Year: "year",
    // "Provision State": "provisionState",
    "License Plate Number": "licensePlate",
    "Fuel Tank Capacity": "tankCapacity",
    // "Fuel Type": "fuelType",
    // "Odometer": "odometer",
    Errors: "error",
  };

  const [showLoading, setShowLoading] = useState<boolean>(false);
  const [showComplete, setShowComplete] = useState<boolean>(false);
  const [csvData, setCSVData] = useState<CSVRow[] | null>(null);
  const [isCsvValidationError, setCsvValidationError] = useState(false);

  const createVehicles = async () => {
    if (csvData && csvData?.length > 0) {
      const response = await createMultipleVehicles({
        variables: {
          data: {
            vehicles: csvData.map((vehicleObj) => {
              const newVehicleObj = {
                ...vehicleObj,
              };
              delete newVehicleObj.error;
              return newVehicleObj;
            }),
          },
        },
      });
      if (response?.data?.createMultipleVehicles?.code === "200") {
        Toast({
          type: "success",
          message:
            response?.data?.createMultipleVehicles?.message ||
            "Vehicle(s) uploaded successfully",
        });
        setShowLoading(false);
        setShowComplete(true);
        refetchVehicles();
      }
    } else {
      throw new Error("No data found");
    }
  };

  // Declare the type for formikProps
  type FormikValues = {
    vehiclesFile: File | null;
  };

  const initialValues: FormikValues = { vehiclesFile: null };

  const parseAndValidateFile = (file: File | null) => {
    setCsvValidationError(false);
    if (!file) {
      return false;
    }
    const parser = new Parser({
      delimiter: ",",
      columns: true,
      skip_empty_lines: true,
    });
    const parsedRows: CSVRow[] = [];

    parser.on("data", async (row: Record<string, string>) => {
      const mappedRow: CSVRow = {
        fleetName: row["Vehicle Name"],
        // eslint-disable-next-line
        vin: row["VIN"],
        licensePlate: row["License Plate Number"],
        // eslint-disable-next-line
        make: row["Make"],
        // eslint-disable-next-line
        model: row["Model"],
        // eslint-disable-next-line
        year: row["Year"],
        tankCapacity: row["Fuel Tank Capacity"]
          ? Number(parseFloat(row["Fuel Tank Capacity"]).toFixed(2))
          : 0,
      };
      try {
        await appVehicleValidationSchema.validate(mappedRow, {
          abortEarly: false,
        });
        mappedRow.error = "";
        parsedRows.push(mappedRow);
      } catch (validationError) {
        setCsvValidationError(true);
        const yupValidationError = validationError as Yup.ValidationError;
        const uniqueErrorsSet = new Set(yupValidationError.errors);
        const uniqueErrorsArray = [...uniqueErrorsSet];
        mappedRow.error = uniqueErrorsArray.join(", ");
        parsedRows.push(mappedRow);
      }
    });

    parser.on("end", () => {
      setCSVData(parsedRows);
    });

    parser.on("error", (err) => {
      console.error(err.message);
      setShowLoading(false);
    });

    const reader = new FileReader();
    reader.onload = (e) => {
      const csvText = e.target?.result as string;
      parser.write(csvText);
      parser.end();
    };

    reader.readAsText(file);
    return true;
  };

  // Function to generate a CSV string from the data
  const generateCSVString = (data: CSVRow[]): string => {
    const lines: string[] = [];

    // Create the CSV header based on the columnMapping
    const header = Object.keys(columnMapping)
      .map((columnName) => `"${columnName}"`)
      .join(",");
    lines.push(header);

    // Create CSV rows
    for (const row of data) {
      // Map each column name to the corresponding value
      const columnValues = Object.values(columnMapping).map(
        (columnName) => `"${row[columnName]}"`,
      );
      lines.push(columnValues.join(","));
    }

    return lines.join("\n");
  };

  const downloadErrorFile = () => {
    if (csvData) {
      const csvString = generateCSVString(csvData);
      const blob = new Blob([csvString], { type: "text/csv" });
      const url = URL.createObjectURL(blob);

      const a = document.createElement("a");
      a.href = url;
      a.download = "vehicle_file_errors.csv";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      URL.revokeObjectURL(url);
    }
  };

  const downloadTemplateFile = () => {
    const header = Object.keys(columnMapping)
      .filter((columnName) => columnName !== "Errors")
      .map((columnName) => `"${columnName}"`)
      .join(",");

    const blob = new Blob([header], { type: "text/csv" });
    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "vehicle_file.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(url);
  };

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    touched,
    isSubmitting,
    errors,
    setFieldValue,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema: appVehicleFileValidationSchema,
    onSubmit: async (value: FormikValues) => {
      try {
        setShowLoading(true);
        parseAndValidateFile(value.vehiclesFile);
        await createVehicles();
      } catch (err: unknown) {
        if (err instanceof Error) {
          Toast({ type: "error", message: err.message });
        } else {
          Toast({ type: "error", message: "Something went wrong" });
        }
        setShowLoading(false);
      }
    },
  });

  const header = (
    <div className="flex flex-row items-center justify-between text-lg font-medium leading-6 text-center text-gray-900">
      <div className="flex flex-row">
        <div>Upload Vehicles File</div>
      </div>
      {!showLoading && !showComplete && (
        <Button
          type={ButtonType.Button}
          size={ButtonSize.AppSize}
          onClick={downloadTemplateFile}
          variant={ButtonVariant.GrayOutline}
          className="inline-flex"
        >
          Download Template
        </Button>
      )}
    </div>
  );

  const footer = (
    <div className="flex flex-row justify-end">
      {!showLoading && (
        <Button
          variant={ButtonVariant.GrayOutline}
          size={ButtonSize.AppSize}
          type={ButtonType.Button}
          onClick={() => {
            resetForm();
            setUploadVehiclesFilePopup(false);
          }}
        >
          Close
        </Button>
      )}

      {!showLoading && !showComplete && (
        <Button
          type={ButtonType.Button}
          variant={ButtonVariant.Black}
          size={ButtonSize.AppSize}
          loading={isSubmitting}
          disabled={isSubmitting}
          onClick={() => {
            if (isCsvValidationError) {
              downloadErrorFile();
            } else {
              handleSubmit();
            }
          }}
          className="inline-flex"
        >
          {isCsvValidationError ? "Download Error Log" : "Import"}
        </Button>
      )}
    </div>
  );

  return (
    <Dialog
      header={<div className="text-base">{header}</div>}
      visible={uploadVehiclesFilePopup}
      style={{ fontFamily: "Inter" }}
      className="w-[95%] sm:w-3/4 lg:max-w-[750px] md:max-w-[500px]"
      footer={footer}
      closable={false}
      onHide={() => setUploadVehiclesFilePopup(false)}
    >
      <div>
        {showLoading || showComplete ? (
          <ExportImportModalLoader
            showLoading={showLoading}
            showComplete={showComplete}
            isImport={true}
          />
        ) : (
          <div className="relative w-full">
            <div className="mb-1 text-sm whitespace-AppSize">
              Upload a CSV file with the following columns: Vehicle Name, VIN,
              Make, Model, Year, License Plate Number and Fuel Tank Capacity.
            </div>

            <div className="flex items-center justify-center w-full mx-auto text-gray-500 bg-gray-200 rounded-lg cursor-pointer">
              <label className="flex flex-col w-full h-32 cursor-pointer ">
                <div className="flex flex-col items-center justify-center pt-7">
                  <DocumentMagnifyingGlassIcon
                    className="w-10 h-10 text-gray-500"
                    aria-hidden="true"
                  />
                  <p className="pt-1 text-sm tracking-wider group-hover:text-gray-600">
                    Browse
                  </p>
                  <div className="w-full px-2 overflow-y-hidden text-center max-h-6">
                    {values.vehiclesFile instanceof File &&
                      values.vehiclesFile?.name}
                  </div>
                </div>
                <input
                  type="file"
                  id="vehiclesFile"
                  onChange={(event) => {
                    if (event?.target?.files) {
                      setFieldValue("vehiclesFile", event.target.files[0]);
                    }
                  }}
                  onClick={(event) => {
                    const input = event.target as HTMLInputElement;
                    input.value = "";
                    setFieldValue("vehiclesFile", null);
                  }}
                  className="opacity-0"
                  onBlur={handleBlur}
                  accept=".csv"
                />
              </label>
            </div>

            {touched.vehiclesFile && (
              <div>
                <div className="mt-1 text-sm text-red-500">
                  {String(errors?.vehiclesFile || "")}
                </div>
                {isCsvValidationError ? (
                  <div className="mt-1 text-sm text-red-500">
                    There are some errors in the Vehicles file
                  </div>
                ) : null}
              </div>
            )}
            <div className="flex items-center justify-between mt-1 text-sm text-gray-500">
              <span>Accepted file type: csv only (Maximum size: 1MB)</span>{" "}
              <span className="flex items-center ml-4">
                <LockClosedIcon className="w-4 h-4 mr-1" />
                secure
              </span>
            </div>
          </div>
        )}
      </div>
    </Dialog>
  );
}
