/** @format */
import { useMutation, useQuery } from "@apollo/client";
import { ENUM_NOTIFICATION_CHANNEL } from "@prisma/client";
import { appChildUserAccountSchema } from "@roadflex/constants";
import {
  CREATE_ACCOUNT_USER,
  CREATE_ACCOUNT_USER_RESEND_INVITATION,
  DELETE_ACCOUNT_USER,
  READ_ACCOUNT_USERS,
  READ_ACCOUNT_USERS_VERIFY,
  UPDATE_ACCOUNT_USER,
  UPDATE_ACCOUNT_USER_VERIFY,
} from "@roadflex/graphql";
import {
  ChildUserResendEmailType,
  ChildUserType,
  ChildUserVerifyType,
  IdInputType,
} from "@roadflex/types";
import { useFormik } from "formik";
import * as Yup from "yup";

type useCreateAccountUserProps = {
  initialValues: ChildUserType;
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
  appChildUserValidationSchema: Yup.AnyObjectSchema;
};
type useUpdateAccountUserProps = {
  initialValues: ChildUserType;
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
  appChildUserValidationSchema: Yup.AnyObjectSchema;
};

type useUpdateAccountUserVerifyProps = {
  initialValues: ChildUserVerifyType;
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

type useDeleteAccountUserProps = {
  initialValues: IdInputType;
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

type useResendInvitationProps = {
  initialValues: ChildUserResendEmailType;
  onSubmit: (
    val: {
      code: string;
      message: string;
    } | null,
    err: Error | null,
  ) => void;
};

export const useCreateAccountUser = ({
  onSubmit,
  initialValues,
  appChildUserValidationSchema,
}: useCreateAccountUserProps) => {
  const [createAccountUserFn] = useMutation<{
    createAccountUser: {
      code: string;
      message: string;
    };
  }>(CREATE_ACCOUNT_USER);

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    isSubmitting,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues
  } = useFormik({
    initialValues,
    validationSchema: appChildUserValidationSchema,
    onSubmit: async (value) => {
      const data: ChildUserType = {
        email: value.email,
        firstName: value.firstName,
        lastName: value.lastName,
        roleName: value.roleName,
        phoneNumber: value.phoneNumber?.trim()?.match(/\d+/g)?.join("") || "",
        id: value.id,
        owner: value.owner,
        userNotification: value.userNotification,
      };

      try {
        const res = await createAccountUserFn({
          variables: { data },
        });
        if (res?.data?.createAccountUser) {
          onSubmit(res?.data?.createAccountUser, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues,
  };
};
export const useReadAccountUsers = (id: string | null) => {
  const { data, refetch, ...rest } = useQuery(READ_ACCOUNT_USERS, {
    fetchPolicy: "no-cache",
    variables: {
      data: { id: id },
    },
  });
  return { data, refetch, ...rest };
};
export const useReadAccountUsersVerify = (token: string | null) => {
  const { data, refetch, ...rest } = useQuery(READ_ACCOUNT_USERS_VERIFY, {
    fetchPolicy: "no-cache",
    variables: {
      data: { token: token },
    },
  });
  return { data, refetch, ...rest };
};
export const useUpdateAccountUser = ({
  onSubmit,
  initialValues,
  appChildUserValidationSchema,
}: useUpdateAccountUserProps) => {
  const [updateAccountUserFn] = useMutation<{
    updateAccountUser: {
      code: string;
      message: string;
    };
  }>(UPDATE_ACCOUNT_USER, {
    refetchQueries: [READ_ACCOUNT_USERS],
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    isSubmitting,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues
  } = useFormik({
    initialValues,
    validationSchema: appChildUserValidationSchema,
    onSubmit: async (value) => {
      const newUserNotification = value.userNotification.map((current) => {
        let comm: ENUM_NOTIFICATION_CHANNEL = ENUM_NOTIFICATION_CHANNEL.NONE;
        if (current?.sms && current?.email) {
          comm = ENUM_NOTIFICATION_CHANNEL.EMAIL_AND_SMS;
        } else if (!current?.sms && current?.email) {
          comm = ENUM_NOTIFICATION_CHANNEL.EMAIL;
        } else if (current?.sms && !current?.email) {
          comm = ENUM_NOTIFICATION_CHANNEL.SMS;
        }
        return {
          id: current.id,
          communicationMode: comm,
        };
      });

      const newParameterFilter = value?.parameterFilter?.map((current) => {
        return {
          tag: current.tag,
          readWritePermission: current.readWritePermission,
        };
      });

      const data = {
        email: value.email,
        firstName: value.firstName,
        lastName: value.lastName,
        roleName: value.roleName,
        phoneNumber: value.phoneNumber?.trim()?.match(/\d+/g)?.join("") || "",
        id: value.id,
        owner: value.owner,
        userNotification: newUserNotification,
        parameterFilter: newParameterFilter,
        status: value.status,
      };

      try {
        const res = await updateAccountUserFn({
          variables: { data },
        });
        if (res?.data?.updateAccountUser) {
          onSubmit(res?.data?.updateAccountUser, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues,
  };
};
export const useUpdateAccountUserVerify = ({
  onSubmit,
  initialValues,
}: useUpdateAccountUserVerifyProps) => {
  const [updateAccountUserVerifyFn] = useMutation<{
    updateAccountUserVerify: {
      code: string;
      message: string;
    };
  }>(UPDATE_ACCOUNT_USER_VERIFY, {
    refetchQueries: [READ_ACCOUNT_USERS_VERIFY],
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    isSubmitting,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues
  } = useFormik({
    initialValues,
    validationSchema: appChildUserAccountSchema,
    onSubmit: async (value) => {
      const data: ChildUserVerifyType = {
        password: value.password,
        retypePassword: value.retypePassword,
        phoneNumber: value.phoneNumber?.trim()?.match(/\d+/g)?.join("") || "",
        otp: value.otp,
        id: value.id,
      };
      try {
        const res = await updateAccountUserVerifyFn({
          variables: {
            data,
          },
        });
        if (res?.data?.updateAccountUserVerify) {
          onSubmit(res?.data?.updateAccountUserVerify, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    values,
    handleChange,
    handleSubmit,
    isSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues,
  };
};
export const useDeleteAccountUser = ({
  onSubmit,
  initialValues,
}: useDeleteAccountUserProps) => {
  const [deleteChildUserFn] = useMutation<{
    deleteAccountUser: {
      code: string;
      message: string;
    };
  }>(DELETE_ACCOUNT_USER, {
    refetchQueries: [READ_ACCOUNT_USERS],
  });

  const {
    handleSubmit: deleteAccountUserSubmit,
    values: deleteAccountUserValues,
    isSubmitting: deleteAccountUserSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      try {
        const res = await deleteChildUserFn({
          variables: {
            data: { idList: [value.id] },
          },
        });
        if (res?.data?.deleteAccountUser) {
          onSubmit(res?.data?.deleteAccountUser, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    deleteAccountUserSubmit,
    deleteAccountUserValues,
    deleteAccountUserSubmitting,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues,
  };
};
export const useResendInvitation = ({
  onSubmit,
  initialValues,
}: useResendInvitationProps) => {
  const [resendInvitationFn] = useMutation<{
    createAccountUserResendNotification: {
      message: string;
      code: string;
    };
  }>(CREATE_ACCOUNT_USER_RESEND_INVITATION);

  const {
    handleSubmit: resendInvitationSubmit,
    values: resendInvitationValues,
    isSubmitting: resendInvitationSubmitting,
    setValues: resendInvitationSetValues,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues
  } = useFormik({
    initialValues,
    onSubmit: async (value) => {
      try {
        const res = await resendInvitationFn({
          variables: {
            data: { id: value.id },
          },
        });
        if (res?.data?.createAccountUserResendNotification) {
          onSubmit(res?.data?.createAccountUserResendNotification, null);
        }
      } catch (error) {
        if (error instanceof Error) {
          onSubmit(null, error);
        } else {
          onSubmit(null, new Error("Something went wrong"));
        }
      }
    },
  });

  return {
    resendInvitationSubmit,
    resendInvitationValues,
    resendInvitationSubmitting,
    resendInvitationSetValues,
    handleBlur,
    errors,
    touched,
    resetForm,
    setFieldValue,
    ...restFormikValues,
  };
};
