import { ApolloError } from "@apollo/client";
import {
  ChevronDownIcon,
  ChevronRightIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  useLazyReadAppCardData,
  useLazyReadBlockedMerchantsByMerchantIdFromAccount,
  useUpdateBlockMerchantByNetworkId,
} from "@roadflex/react-hooks";
import { CardShortened } from "@roadflex/types";
import classNames from "classnames";
import { Dialog } from "primereact/dialog";
import React, { useEffect, useMemo, useState } from "react";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";
import { CustomMultiSelect } from "../../dropdowns";
import { TextInput } from "../../inputs";
import Toast from "../../toast-message/toast";

interface Merchant {
  networkId: string;
  merchantName?: string;
}

async function fetchBlockedData() {
  // Simulate a network request
  return new Promise<{
    accountBlocked: Merchant[];
  }>((resolve) => {
    setTimeout(() => {
      resolve({
        accountBlocked: [
          { networkId: "NET-100", merchantName: "Account Merchant A" },
          { networkId: "NET-101", merchantName: "Account Merchant B" },
        ],
      });
    }, 500);
  });
}

interface EditBlockedMerchantsByMerchantIdModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const EditBlockedMerchantsByMerchantIdModal: React.FC<
  EditBlockedMerchantsByMerchantIdModalProps
> = ({ isOpen, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [accountBlocked, setAccountBlocked] = useState<Merchant[]>([]);
  const [cardsData, setCardsData] = useState<CardShortened[]>([]);
  const { cardsListFn, cards } = useLazyReadAppCardData();
  const {
    blockedMerchantsByMerchantIdFromAccountFn,
    blockedMerchantsByMerchantIdFromAccount,
  } = useLazyReadBlockedMerchantsByMerchantIdFromAccount();
  const { blockMerchantByNetworkIdFn } = useUpdateBlockMerchantByNetworkId();
  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      blockedMerchantsByMerchantIdFromAccountFn({ variables: {} });
    }
  }, [isOpen]);

  useEffect(() => {
    if (cards.length > 0) {
      setCardsData(cards);
      setIsLoading(false);
    }
  }, [cards]);

  useEffect(() => {
    if (blockedMerchantsByMerchantIdFromAccount.length > 0) {
      setAccountBlocked(blockedMerchantsByMerchantIdFromAccount);
      setIsLoading(false);
    }
  }, [blockedMerchantsByMerchantIdFromAccount]);

  const [expandedCards, setExpandedCards] = useState<string[]>([]);

  const [newAcctMerchantId, setNewAcctMerchantId] = useState("");

  const handleBlockAccountMerchant = async () => {
    const trimmed = newAcctMerchantId.trim();
    if (!trimmed) return;
    setIsLoading(true);

    try {
      const res = await blockMerchantByNetworkIdFn({
        variables: {
          data: {
            merchantNetworkId: {
              merchantName: "",
              networkId: trimmed,
            },
            action: "block",
            blockLevel: "cardholder",
            cardsList: [],
          },
        },
      });

      if (res?.data?.updateBlockedMerchantsNetworkIds?.code === "200") {
        Toast({
          type: "success",
          message: res?.data?.updateBlockedMerchantsNetworkIds.message,
        });
      } else {
        Toast({
          type: "error",
          message:
            res?.data?.updateBlockedMerchantsNetworkIds.message ||
            "Somthing went wrong",
        });
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        Toast({ type: "error", message: err.message });
      } else {
        Toast({ type: "error", message: "An error is thrown" });
      }
    }
    setAccountBlocked((prev) => [
      ...prev,
      { networkId: trimmed, merchantName: "" },
    ]);
    setNewAcctMerchantId("");

    setIsLoading(false);
  };

  const handleUnblockAccountMerchant = async (merchantId: string) => {
    setIsLoading(true);

    try {
      const res = await blockMerchantByNetworkIdFn({
        variables: {
          data: {
            merchantNetworkId: {
              merchantName: "",
              networkId: merchantId,
            },
            action: "unblock",
            blockLevel: "cardholder",
            cardsList: [],
          },
        },
      });

      if (res?.data?.updateBlockedMerchantsNetworkIds?.code === "200") {
        Toast({
          type: "success",
          message: res?.data?.updateBlockedMerchantsNetworkIds.message,
        });
      } else {
        Toast({
          type: "error",
          message:
            res?.data?.updateBlockedMerchantsNetworkIds.message ||
            "Somthing went wrong",
        });
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        Toast({ type: "error", message: err.message });
      } else {
        Toast({ type: "error", message: "An error is thrown" });
      }
    }
    setAccountBlocked((prev) => prev.filter((m) => m.networkId !== merchantId));

    setIsLoading(false);
  };

  const handleUnblockCardMerchant = async (id: string, merchantId: string) => {
    setIsLoading(true);
    try {
      const res = await blockMerchantByNetworkIdFn({
        variables: {
          data: {
            merchantNetworkId: {
              merchantName: "",
              networkId: merchantId,
            },
            action: "unblock",
            blockLevel: "card",
            cardsList: [id],
          },
        },
      });

      if (res?.data?.updateBlockedMerchantsNetworkIds?.code === "200") {
        Toast({
          type: "success",
          message: res?.data?.updateBlockedMerchantsNetworkIds.message,
        });
      } else {
        Toast({
          type: "error",
          message:
            res?.data?.updateBlockedMerchantsNetworkIds.message ||
            "Somthing went wrong",
        });
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        Toast({ type: "error", message: err.message });
      } else {
        Toast({ type: "error", message: "An error is thrown" });
      }
    }
    setCardsData((prev) =>
      prev.map((card) => {
        if (card.id === id) {
          return {
            ...card,
            blockedMerchants: (card.blockedMerchantsNetworkIds || []).filter(
              (m) => m.networkId !== merchantId,
            ),
          };
        }
        return card;
      }),
    );
    setIsLoading(false);
  };

  const [newCardMerchantId, setNewCardMerchantId] = useState("");
  const [selectedCardIds, setSelectedCardIds] = useState<string[]>([]);
  const [tabOpen, setTabOpen] = useState(1);

  const handleBlockMerchantOnSelectedCards = async () => {
    const trimmed = newCardMerchantId.trim();
    if (!trimmed || !selectedCardIds.length) return;
    setIsLoading(true);

    try {
      const res = await blockMerchantByNetworkIdFn({
        variables: {
          data: {
            merchantNetworkId: {
              merchantName: "",
              networkId: trimmed,
            },
            action: "block",
            blockLevel: "card",
            cardsList: selectedCardIds,
          },
        },
      });

      if (res?.data?.updateBlockedMerchantsNetworkIds?.code === "200") {
        Toast({
          type: "success",
          message: res?.data?.updateBlockedMerchantsNetworkIds.message,
        });
      } else {
        Toast({
          type: "error",
          message:
            res?.data?.updateBlockedMerchantsNetworkIds.message ||
            "Somthing went wrong",
        });
      }
    } catch (err) {
      if (err instanceof ApolloError) {
        Toast({ type: "error", message: err.message });
      } else {
        Toast({ type: "error", message: "An error is thrown" });
      }
    }

    setCardsData((prev) =>
      prev.map((card) => {
        if (selectedCardIds.includes(card.id)) {
          // If not already in blocked list, add it
          const alreadyBlocked = (card.blockedMerchantsNetworkIds || []).some(
            (m) => m.networkId.toLowerCase() === trimmed.toLowerCase(),
          );
          if (!alreadyBlocked) {
            return {
              ...card,
              blockedMerchants: [
                ...(card.blockedMerchantsNetworkIds || []),
                { networkId: trimmed, merchantName: "" },
              ],
            };
          }
        }
        return card;
      }),
    );
    setNewCardMerchantId("");
    setSelectedCardIds([]);

    setIsLoading(false);
  };

  const onTabChange = (tabId: number) => {
    setTabOpen(tabId);
    if (tabId === 2 && cards.length === 0) {
      setIsLoading(true);
      cardsListFn({
        variables: {
          data: {
            mode: "Shortened",
            returnPhysical: true,
            returnVirtual: true,
            returnDeliveredOnly: false,
          },
        },
      });
    }
  };
  const [cardSearch, setCardSearch] = useState("");
  const filteredCards = useMemo(() => {
    const term = cardSearch.trim().toLowerCase();
    if (!term) return cardsData;
    return cardsData.filter((c) => {
      const fourMatch = c.lastFour.includes(term);
      return fourMatch;
    });
  }, [cardSearch, cardsData]);

  const toggleCardExpansion = (cardId: string) => {
    setExpandedCards((prev) =>
      prev.includes(cardId)
        ? prev.filter((id) => id !== cardId)
        : [...prev, cardId],
    );
  };

  const footer = (
    <div className="flex flex-row justify-end">
      <Button
        type={ButtonType.Button}
        variant={ButtonVariant.SecondaryOutline}
        size={ButtonSize.AppSize}
        onClick={() => onClose()}
        className="!mr-0"
      >
        Close
      </Button>
    </div>
  );

  const tabs = [
    { id: 1, name: "Account Level", href: "", current: true },
    { id: 2, name: "Card Level", href: "", current: false },
  ];

  if (!isOpen) return null;

  return (
    <Dialog
      header={
        <div className="text-base">
          Block / Unblock Merchants by Merchant ID
        </div>
      }
      style={{
        width: "80vw",
        fontFamily: "Inter",
      }}
      visible={isOpen}
      onHide={() => onClose()}
      closeOnEscape={true}
      closable={false}
      footer={footer}
    >
      {/* Tabs */}
      <div className="">
        <div className="mt-4 text-sm md:text-base md:mt-0">
          <div className="mb-8 border-b border-brown-500">
            <nav className="flex -mb-px space-x-8 overflow-auto">
              {tabs.map((tab) => (
                <a
                  key={tab.name}
                  href={tab.href}
                  className={classNames(
                    tabOpen === tab.id
                      ? "border-black text-black"
                      : "border-transparent hover:border-black",
                    "whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm cursor-pointer",
                  )}
                  onClick={(e) => {
                    e.preventDefault();
                    onTabChange(tab.id);
                  }}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>

        <div className="flex-1 mt-4 overflow-y-auto md:mt-6">
          <div className={`${tabOpen === 1 ? `` : `hidden`} `}>
            <div className="space-y-6">
              <div>
                <p className="mb-2 text-sm font-medium text-gray-700">
                  Currently Blocked (Account Level):
                </p>
                {accountBlocked.length > 0 ? (
                  <div className="space-y-2">
                    {accountBlocked.map((m) => (
                      <div
                        key={m.networkId}
                        className="flex flex-wrap items-center justify-between px-2 py-1 m-1 text-sm leading-loose bg-gray-200 rounded md:text-base hover:bg-gray-300"
                      >
                        <span className="text-sm text-gray-700">
                          {m.networkId}
                          {m.merchantName ? ` (${m.merchantName})` : ""}
                        </span>
                        <Button
                          variant={ButtonVariant.Transparent}
                          size={ButtonSize.Small}
                          disabled={isLoading}
                          onClick={() =>
                            handleUnblockAccountMerchant(m.networkId)
                          }
                        >
                          <XMarkIcon className="w-4 h-4"></XMarkIcon>
                        </Button>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-sm text-gray-400">
                    No merchants blocked at the account level.
                  </p>
                )}
              </div>

              <div className="p-4 border border-gray-200 rounded bg-gray-50">
                <p className="mb-2 text-sm font-medium text-gray-700">
                  Block a New Merchant (Account)
                </p>
                <div className="flex flex-col gap-3 ">
                  <TextInput
                    type="text"
                    placeholder="Merchant ID"
                    value={newAcctMerchantId}
                    maxLength={15}
                    onChange={(e) => setNewAcctMerchantId(e.target.value)}
                    disabled={isLoading}
                  ></TextInput>
                  <Button
                    variant={ButtonVariant.Primary}
                    size={ButtonSize.AppSize}
                    onClick={handleBlockAccountMerchant}
                    disabled={!newAcctMerchantId.trim() || isLoading}
                  >
                    Block Merchant by Merchant ID
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className={`${tabOpen === 2 ? `` : `hidden`}`}>
            <div className="space-y-6">
              <div>
                <p className="mb-2 text-sm font-medium text-gray-700">
                  Unblock / View Blocked Merchants Per Card
                </p>
                <TextInput
                  type="text"
                  placeholder="Search by last four"
                  value={cardSearch}
                  onChange={(e) => setCardSearch(e.target.value)}
                  disabled={isLoading}
                ></TextInput>
                <div className="mt-4 space-y-3">
                  {filteredCards.length === 0 && (
                    <p className="text-sm text-gray-500">
                      No matching cards found.
                    </p>
                  )}
                  <div className="max-h-[300px] overflow-auto">
                    {filteredCards.map((card) => {
                      const isExpanded = expandedCards.includes(card.id);
                      const blockedCount = (
                        card.blockedMerchantsNetworkIds || []
                      ).length;
                      return (
                        <div
                          key={card.id}
                          className="bg-white border border-gray-200 rounded"
                        >
                          <button
                            onClick={() => {
                              if (blockedCount > 0) {
                                toggleCardExpansion(card.id);
                              }
                            }}
                            className="flex items-center justify-between w-full px-4 py-2 text-sm font-medium text-left text-gray-700 hover:bg-gray-100"
                            disabled={blockedCount === 0 || isLoading}
                          >
                            <span>
                              Card: •••• {card.lastFour}
                              {blockedCount > 0 && (
                                <span className="ml-1 text-xs text-gray-500">
                                  ({blockedCount} blocked)
                                </span>
                              )}
                            </span>
                            {blockedCount > 0 && (
                              <span className="ml-2 text-gray-400">
                                {isExpanded ? (
                                  <ChevronDownIcon className="w-5 h-5" />
                                ) : (
                                  <ChevronRightIcon className="w-5 h-5" />
                                )}
                              </span>
                            )}
                          </button>
                          {isExpanded && (
                            <div className="px-4 py-2 space-y-2 border-t border-gray-200">
                              {blockedCount > 0 ? (
                                card.blockedMerchantsNetworkIds?.map((m) => (
                                  <div
                                    key={m.networkId}
                                    className="flex flex-wrap items-center justify-between px-2 py-1 m-1 text-sm leading-loose bg-gray-200 rounded md:text-base hover:bg-gray-300"
                                  >
                                    <span className="text-sm text-gray-700">
                                      {m.networkId}
                                      {m.merchantName
                                        ? ` (${m.merchantName})`
                                        : ""}
                                    </span>
                                    <Button
                                      variant={ButtonVariant.Transparent}
                                      size={ButtonSize.Small}
                                      disabled={isLoading}
                                      onClick={() =>
                                        handleUnblockCardMerchant(
                                          card.id,
                                          m.networkId,
                                        )
                                      }
                                    >
                                      <XMarkIcon className="w-4 h-4"></XMarkIcon>
                                    </Button>
                                  </div>
                                ))
                              ) : (
                                <p className="text-sm text-gray-400">
                                  No merchants blocked on this card.
                                </p>
                              )}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="p-4 border border-gray-200 rounded bg-gray-50">
                <p className="mb-2 text-sm font-medium text-gray-700">
                  Block a New Merchant on Multiple Cards
                </p>
                <div className="flex flex-col gap-3 ">
                  <TextInput
                    type="text"
                    placeholder="Merchant ID"
                    value={newCardMerchantId}
                    maxLength={15}
                    onChange={(e) => setNewCardMerchantId(e.target.value)}
                    disabled={isLoading}
                  ></TextInput>
                  <CustomMultiSelect
                    value={selectedCardIds}
                    onChange={(e) => {
                      setSelectedCardIds(e.value);
                    }}
                    options={cardsData.map((card) => {
                      return {
                        label: card.lastFour,
                        value: card.id,
                      };
                    })}
                    disabled={isLoading}
                    placeholder="Select Card"
                  />

                  <Button
                    variant={ButtonVariant.Primary}
                    size={ButtonSize.AppSize}
                    onClick={handleBlockMerchantOnSelectedCards}
                    disabled={!newCardMerchantId.trim() || isLoading}
                  >
                    Block Merchant by Merchant ID
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
