/** @format */
import { useQuery } from "@apollo/client";
import { IMPORTED_TRANSACTION_LIST } from "@roadflex/graphql";
import { TransactionType } from "@roadflex/types";

export const useImportedTransactionList = () => {
  const {
    data: transactionData,
    refetch: transactionRefetch,
    loading: transactionLoading,
  } = useQuery<{
    readImportedTransactions: {
      code: string;
      message: string;
      transactions: TransactionType[];
    };
  }>(IMPORTED_TRANSACTION_LIST, {
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
  });

  return {
    transactionData,
    transactionRefetch,
    transactionLoading,
  };
};
