/* eslint-disable max-lines */
/* eslint-disable max-lines-per-function */
/** @format */

import { LifebuoyIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { STATE_CODES } from "@roadflex/constants";
import { CustomMultiSelect } from "@roadflex/react-components";
import { CardShortened } from "@roadflex/types";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../../buttons";
import { TextInput } from "../../inputs";

type GenericActionModalProps = {
  title: string;
  helpGuide: string;
  open: boolean;
  setOpen: (val: boolean) => void;
  buttonName: string;
  action: () => void;
  updating: boolean;
  blockMerchantsArray?: string[];
  setBlockMerchantsArray?: (val: string[]) => void;
  deliveredCards?: CardShortened[];
  setFieldValue?: (field: string, value: string[] | string) => void;
  blockStatesArray?: string[];
  setBlockStatesArray?: (val: string[]) => void;
};

export const GenericActionModal = ({
  title,
  helpGuide,
  open,
  setOpen,
  buttonName,
  action,
  updating,
  blockMerchantsArray,
  setBlockMerchantsArray,
  deliveredCards,
  setFieldValue,
  blockStatesArray,
  setBlockStatesArray,
}: GenericActionModalProps) => {
  const [addedBlockMerchant, setAddedBlockMerchant] = useState("");
  const [selectedCards, setSelectedCards] = useState<CardShortened[]>([]);

  const footer = (
    <div className="flex flex-row justify-end">
      <Button
        variant={ButtonVariant.Primary}
        size={ButtonSize.AppSize}
        disabled={
          updating || (blockMerchantsArray && blockMerchantsArray.length >= 20)
        }
        className="ml-3"
        onClick={() => {
          if (blockMerchantsArray && setBlockMerchantsArray) {
            const array = blockMerchantsArray;
            const newElement = addedBlockMerchant
              .replace(/^\s+|\s+$/g, "")
              .toUpperCase();
            if (array.indexOf(newElement) < 0) {
              array.push(newElement);
              setBlockMerchantsArray(array);
              action();
              setAddedBlockMerchant("");
            }
          }
          if (deliveredCards && setFieldValue) {
            const cardIdList = selectedCards.map((card) => {
              return card.id;
            });
            setFieldValue("cardIdList", cardIdList);
            action();
          }
          if (blockStatesArray && setBlockStatesArray) {
            action();
          }
        }}
      >
        {buttonName}
      </Button>
      <Button
        type={ButtonType.Button}
        variant={ButtonVariant.SecondaryOutline}
        size={ButtonSize.AppSize}
        disabled={updating}
        className="ml-3"
        onClick={() => setOpen(false)}
      >
        Close
      </Button>
    </div>
  );
  return (
    <Dialog
      header={<div className="text-base">{title}</div>}
      style={{
        width: "80vw",
        fontFamily: "Inter",
      }}
      visible={open}
      onHide={() => setOpen(false)}
      closeOnEscape={true}
      closable={false}
      footer={footer}
    >
      <div className="flex flex-col h-full p-4 overflow-y-auto bg-white">
        {helpGuide && (
          <div className="p-4 mb-8 text-sm text-white bg-gray-700 rounded-sm">
            <div className="flex items-center justify-start">
              <LifebuoyIcon
                className="flex-shrink-0 w-4 h-4"
                aria-hidden="true"
              />
              <span className="ml-2 font-bold">Help Guide</span>
            </div>
            <div>{helpGuide}</div>
          </div>
        )}
        <div className="flex flex-col justify-between flex-grow h-full">
          <div className="space-y-4">
            <div className="text-sm md:text-base">
              {blockMerchantsArray &&
                blockMerchantsArray.map((e) => {
                  return (
                    <span
                      key={e}
                      className="flex flex-wrap items-center justify-between px-2 py-1 m-1 text-sm leading-loose bg-gray-200 rounded md:text-base hover:bg-gray-300"
                    >
                      {e}
                      <Button
                        variant={ButtonVariant.Transparent}
                        size={ButtonSize.Small}
                        disabled={updating}
                        onClick={(event) => {
                          if (e && e.trim() !== "") {
                            const array = blockMerchantsArray;
                            if (array.indexOf(e.trim()) >= 0) {
                              array.splice(array.indexOf(e.trim()), 1);
                            }
                            if (setBlockMerchantsArray) {
                              setBlockMerchantsArray(array);
                            }
                            action();
                          }
                        }}
                      >
                        <XMarkIcon className="w-4 h-4"></XMarkIcon>
                      </Button>
                    </span>
                  );
                })}
              {blockMerchantsArray && blockMerchantsArray.length === 0 && (
                <div className="mb-4 text-sm text-left md:text-base">
                  {" "}
                  No merchants on blocked list.
                </div>
              )}
              {blockStatesArray && (
                <div className="block w-full">
                  <CustomMultiSelect
                    name="state"
                    id="state"
                    className="border-none"
                    value={blockStatesArray}
                    onChange={(e) => {
                      if (setBlockStatesArray) {
                        setBlockStatesArray(e.target.value);
                      }
                    }}
                    options={STATE_CODES.map((m) => {
                      return {
                        label: `${m.label} (${m.value})`,
                        value: m.value,
                        key: m.value,
                        disabled: false,
                      };
                    })}
                  ></CustomMultiSelect>
                </div>
              )}
              {deliveredCards && (
                <DataTable
                  size="small"
                  stripedRows
                  value={deliveredCards}
                  paginator
                  rows={deliveredCards?.length || 1000}
                  dataKey="id"
                  style={{
                    fontFamily: "Inter",
                  }}
                  sortField="lastFour"
                  sortOrder={-1}
                  responsiveLayout="stack"
                  emptyMessage="No available records"
                  selectionMode="checkbox"
                  selection={selectedCards}
                  onSelectionChange={(e) => {
                    setSelectedCards(e.value);
                  }}
                  className="!bg-white custom-table !text-brown-500 !border-brown-500 datatable-no-footer"
                >
                  <Column
                    selectionMode="multiple"
                    headerStyle={{ width: "3em" }}
                    bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                    headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  ></Column>
                  <Column
                    sortable
                    field="lastFour"
                    header="Last Four"
                    className="text-sm"
                    bodyClassName="text-brown-500 !py-1 text-sm"
                    headerClassName="!bg-white !text-brown-500 !border-brown-500"
                    body={(r: CardShortened) => `${r.lastFour}`}
                  />
                  <Column
                    field="fleetDriver"
                    header="Driver"
                    className="text-sm"
                    bodyClassName="text-brown-500 !py-1 text-sm"
                    headerClassName="!bg-white !text-brown-500 !border-brown-500"
                    body={(r: CardShortened) =>
                      `${(r.fleetDriver && r.fleetDriver[0]?.name) || "-"}`
                    }
                  />
                  <Column
                    field="fleet"
                    header="Vehicle"
                    className="text-sm"
                    bodyClassName="text-brown-500 !py-1 text-sm"
                    headerClassName="!bg-white !text-brown-500 !border-brown-500"
                    body={(r: CardShortened) =>
                      `${(r.fleet && r.fleet[0]?.fleetName) || "-"}`
                    }
                  />
                </DataTable>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col w-full pt-8 mb-4 border-brown-500">
          {blockMerchantsArray && (
            <div>
              <TextInput
                type="text"
                value={addedBlockMerchant}
                placeholder="Merchant Name"
                name="blockedMerchants"
                id="blockedMerchants"
                onChange={(e) => setAddedBlockMerchant(e.target.value)}
              />
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};
