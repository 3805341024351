type OnboardBannerComponentProps = {
  header: string;
  subText?: string;
  subText2?: string;
};

export const OnboardBannerComponent = ({
  header,
  subText,
  subText2,
}: OnboardBannerComponentProps) => {
  return (
    <div className="w-full text-center py-14 md:block md:py-28 bg-brown-800">
      <div className={`flex flex-col justify-center h-full animate-slidein`}>
        <div className="mb-3 text-2xl font-semibold text-white md:text-3xl lg:text-4xl">
          <h1 className="px-2 text-center">{header}</h1>
          {subText && (
            <div className="p-3 mt-2 text-lg font-normal md:text-xl lg:text-2xl">
              {subText}
            </div>
          )}
          {subText2 && (
            <div className="p-3 mt-4 text-lg font-normal md:text-xl lg:text-2xl">
              {subText2}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
