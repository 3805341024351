/** @format */

import { CogIcon } from "@heroicons/react/24/outline";
import { ELD_SYSTEMS } from "@roadflex/constants";
import {
  useGeoTabGetTripDetails,
  useGeoTabGetTripHistory,
} from "@roadflex/react-hooks";
import {
  EldDeviceTripDetails,
  GeoTabTripHistory,
  TripDetails,
} from "@roadflex/types";
import { convertISOStringToDate } from "@roadflex/utils";
import { startOfDay } from "date-fns";
import { Calendar, CalendarChangeParams } from "primereact/calendar";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { Splitter, SplitterPanel } from "primereact/splitter";
import { useEffect, useState } from "react";
import { Button, ButtonSize, ButtonVariant } from "../../buttons";
import { newPaginationTemplate } from "../../datatable/pagination-templates/new-pagination-template";
import { Toast } from "../../toast-message/toast";
import { TripHistoryMap } from "./TripHistoryMap";

export interface GeoTabDeviceTripsModalProps {
  openGeoTabDeviceTripsModal: boolean;
  setOpenGeoTabDeviceTripsModal: (value: boolean) => void;
  orgName: string;
  database: string;
  tripDetailValues: TripDetails;
}

const ZERO_FROM_VERSION = "0000000000000000";

export default function GeoTabDeviceTripsModal({
  openGeoTabDeviceTripsModal,
  setOpenGeoTabDeviceTripsModal,
  orgName,
  database,
  tripDetailValues,
}: GeoTabDeviceTripsModalProps) {
  const [geoTabTrips, setGeoTabTrips] = useState<EldDeviceTripDetails[]>([]);
  const [geoTabTripHistory, setGeoTabTripHistory] = useState<
    GeoTabTripHistory[]
  >([]);
  const [fromVersionForTripDetails, setFromVersionForTripDetails] =
    useState(ZERO_FROM_VERSION);
  const [fromVersionForTripHistory, setFromVersionForTripHistory] =
    useState(ZERO_FROM_VERSION);
  const [showTripHistory, setShowTripHistory] = useState(false);
  const [isTripHistoryLoading, setTripHistoryLoading] = useState(true);
  const [tripCoordinatesArray, setTripCoordinatesArray] = useState<
    {
      lat: number;
      lng: number;
    }[]
  >([]);
  const [markersArray, setMarkersArray] = useState<
    {
      position: {
        lat: number;
        lng: number;
      };
      speed: string;
      time: string;
      showInfo: boolean;
      key: number;
    }[]
  >([]);
  const [dates, setDates] = useState<Date[]>(tripDetailValues.dates);
  const onDateRangeChange = (e: CalendarChangeParams) => {
    const dateArray = e.value as Date[];
    setDates(dateArray);
  };

  const initialTripHistoryObj = {
    startDate: "",
    endDate: "",
    fromVersion: fromVersionForTripHistory,
    resultsLimit: 5000,
    deviceId: "",
  };
  const [tripHistoryValues, setTripHistoryValues] = useState({
    ...initialTripHistoryObj,
  });

  const {
    geoTabGetTripDetailsHandleSubmit,
    geoTabGetTripDetailsIsSubmitting,
    geoTabGetTripDetailsSetFieldValue,
  } = useGeoTabGetTripDetails({
    initialValues: {
      orgName,
      database,
      startDate: tripDetailValues.dates[0].toISOString(),
      endDate: tripDetailValues.dates[1].toISOString(),
      fromVersion: fromVersionForTripDetails,
      resultsLimit: tripDetailValues?.resultsLimit,
      deviceId: tripDetailValues.deviceId,
    },

    onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err.message });
        return;
      }
      if (res) {
        // console.log(res, err);
        setFromVersionForTripDetails(
          res?.eldDeviceTripDetails?.fromVersion || "",
        );
        setGeoTabTrips(res?.eldDeviceTripDetails?.eldDeviceTripDetails || []);
        // toast.success("GeoTab device trips successfully retrieved.");
      }
    },
  });

  const {
    geoTabGetTripHistoryHandleSubmit,
    geoTabGetTripHistoryIsSubmitting,
    geoTabGetTripHistorySetFieldValue,
  } = useGeoTabGetTripHistory({
    initialValues: {
      orgName,
      database,
      startDate: "",
      endDate: "",
      fromVersion: "",
      resultsLimit: 5000,
      deviceId: tripDetailValues.deviceId,
    },

    onSubmit(res, err) {
      if (err) {
        Toast({ type: "error", message: err.message });
        return;
      }
      // console.log(res, err);
      const geoTabTripHistoryList =
        res?.eldDeviceTripHistory?.eldDeviceTripHistory || [];
      setFromVersionForTripHistory(
        res?.eldDeviceTripHistory?.fromVersion || "",
      );
      setGeoTabTripHistory([...geoTabTripHistory, ...geoTabTripHistoryList]);
      const tripCoordinatesList = geoTabTripHistoryList?.map((coordinate) => {
        return {
          lat: Number(coordinate.latitude),
          lng: Number(coordinate.longitude),
        };
      });
      const markersList = geoTabTripHistoryList?.map((coordinate, index) => {
        return {
          position: {
            lat: Number(coordinate.latitude),
            lng: Number(coordinate.longitude),
          },
          speed: coordinate.speed,
          time: coordinate.dateTime,
          showInfo: false,
          key: index,
        };
      });
      setTripCoordinatesArray(tripCoordinatesList);
      setMarkersArray(markersList);
      setTripHistoryLoading(false);
    },
  });

  const renderFooter = () => {
    return (
      <div className="flex flex-row justify-end">
        {showTripHistory ? (
          <Button
            variant={ButtonVariant.AppOrange}
            size={ButtonSize.AppSize}
            onClick={() => {
              setShowTripHistory(false);
              setGeoTabTripHistory([]);
              setFromVersionForTripHistory(ZERO_FROM_VERSION);
              setTripHistoryLoading(true);
            }}
          >
            Back to trip details
          </Button>
        ) : null}
        <Button
          variant={ButtonVariant.SecondaryOutline}
          size={ButtonSize.AppSize}
          onClick={() => setOpenGeoTabDeviceTripsModal(false)}
        >
          Close
        </Button>
      </div>
    );
  };

  const tripDistanceTemplate = (rowData: EldDeviceTripDetails) => {
    return (
      <span className="text-brown-500 !py-1 text-sm 5xl:text-base">
        <div>{rowData.distance}</div>
      </span>
    );
  };

  const tripStartTemplate = (rowData: EldDeviceTripDetails) => {
    return (
      <span className="text-brown-500 !py-1 text-sm 5xl:text-base">
        <div>{convertISOStringToDate(rowData.start)}</div>
      </span>
    );
  };

  const tripStopTemplate = (rowData: EldDeviceTripDetails) => {
    return (
      <span className="text-brown-500 !py-1 text-sm 5xl:text-base">
        <div>{convertISOStringToDate(rowData.stop)}</div>
      </span>
    );
  };

  const nextTripStartTemplate = (rowData: EldDeviceTripDetails) => {
    return (
      <span className="text-brown-500 !py-1 text-sm 5xl:text-base">
        <div>{convertISOStringToDate(rowData.nextTripStart)}</div>
      </span>
    );
  };

  const tripHistoryDateTimeTemplate = (rowData: GeoTabTripHistory) => {
    return (
      <span className="text-brown-500 !py-1 text-sm 5xl:text-base">
        <div>{convertISOStringToDate(rowData.dateTime)}</div>
      </span>
    );
  };

  const getTripHistoryTemplate = (rowData: EldDeviceTripDetails) => {
    return (
      <Button
        variant={ButtonVariant.AppOrange}
        size={ButtonSize.AppSize}
        className="text-xs"
        onClick={(e) => {
          setTripHistoryValues({
            startDate: rowData.start,
            endDate: rowData.stop,
            fromVersion: "",
            resultsLimit: 5000,
            deviceId: tripDetailValues.deviceId,
          });
          geoTabGetTripHistorySetFieldValue("orgName", orgName);
          if (orgName === ELD_SYSTEMS.GEOTAB) {
            geoTabGetTripHistorySetFieldValue(
              "fromVersion",
              fromVersionForTripHistory,
            );
          }
          geoTabGetTripHistorySetFieldValue("startDate", rowData.start);
          geoTabGetTripHistorySetFieldValue("endDate", rowData.stop);
          geoTabGetTripHistorySetFieldValue(
            "resultsLimit",
            tripHistoryValues.resultsLimit,
          );
          geoTabGetTripHistorySetFieldValue(
            "deviceId",
            tripDetailValues.deviceId,
          );
          geoTabGetTripHistoryHandleSubmit();
          setShowTripHistory(true);
        }}
      >
        Show trip history
      </Button>
    );
  };

  useEffect(() => {
    setFromVersionForTripDetails(ZERO_FROM_VERSION);
    setFromVersionForTripHistory(ZERO_FROM_VERSION);
    setShowTripHistory(false);
    setTripHistoryLoading(true);
    setGeoTabTripHistory([]);
    setGeoTabTrips([]);
    if (openGeoTabDeviceTripsModal) {
      let startDt = startOfDay(dates[0]);
      let endDt = startOfDay(dates[1]);
      if (!startDt) {
        startDt = new Date();
        startDt.setDate(startDt.getDate() - 1);
      }
      if (!endDt) {
        endDt = new Date();
      }
      geoTabGetTripDetailsSetFieldValue("orgName", orgName);
      if (orgName === ELD_SYSTEMS.GEOTAB) {
        geoTabGetTripDetailsSetFieldValue("fromVersion", ZERO_FROM_VERSION);
        geoTabGetTripHistorySetFieldValue("fromVersion", ZERO_FROM_VERSION);
      } else {
        geoTabGetTripDetailsSetFieldValue("fromVersion", "");
        geoTabGetTripHistorySetFieldValue("fromVersion", "");
      }
      geoTabGetTripDetailsSetFieldValue("startDate", startDt);
      geoTabGetTripDetailsSetFieldValue("endDate", endDt);
      geoTabGetTripDetailsSetFieldValue(
        "resultsLimit",
        tripDetailValues.resultsLimit,
      );
      geoTabGetTripDetailsSetFieldValue("deviceId", tripDetailValues.deviceId);
      geoTabGetTripDetailsHandleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openGeoTabDeviceTripsModal]);

  return (
    <Dialog
      header={
        <div className="flex flex-col flex-wrap justify-start w-full col-span-1 gap-5 sm:w-auto sm:flex-row">
          <div className="flex items-center text-base">{`Trip details for ${tripDetailValues.deviceName}`}</div>
        </div>
      }
      visible={openGeoTabDeviceTripsModal}
      className="w-[95%] sm:w-[80vw]"
      footer={renderFooter()}
      onHide={() => setOpenGeoTabDeviceTripsModal(false)}
    >
      {!showTripHistory ? (
        <div className="flex items-center justify-start col-span-1 gap-4 mb-4 text-base ">
          <div className="flex flex-row items-center justify-start w-full gap-4">
            <label className="flex" htmlFor="dateRange">
              Date Range of Trips
            </label>
            <Calendar
              id="dateRange"
              value={dates}
              onChange={onDateRangeChange}
              selectionMode="range"
              placeholder="Select Date Range"
              className="md:w-1/3 custom-calendar"
              panelClassName="custom-calendar-panel"
              inputClassName="!py-2"
              maxDate={new Date()}
              readOnlyInput
            />
            <Button
              variant={ButtonVariant.AppOrange}
              size={ButtonSize.AppSize}
              onClick={(e) => {
                geoTabGetTripDetailsSetFieldValue("startDate", dates[0]);
                geoTabGetTripDetailsSetFieldValue("endDate", dates[1]);
                geoTabGetTripDetailsHandleSubmit();
                setShowTripHistory(false);
              }}
              disabled={dates[0] === null || dates[1] === null}
            >
              Query
            </Button>
          </div>
        </div>
      ) : null}
      <div className="border-b border-gray-200 shadow sm:rounded-lg ">
        {showTripHistory ? (
          <Splitter style={{ height: "650px" }}>
            <SplitterPanel size={20}>
              <DataTable
                size="small"
                stripedRows
                value={geoTabTripHistory}
                // paginator
                rows={10}
                dataKey="id"
                sortField="dateTime"
                sortOrder={1}
                paginatorTemplate={newPaginationTemplate}
                className="!bg-white custom-table !text-brown-500 !border-brown-500"
                paginator
                paginatorClassName="justify-content-end"
                responsiveLayout="scroll"
                emptyMessage="No available records"
                loading={geoTabGetTripHistoryIsSubmitting}
                style={{ fontFamily: "Inter" }}
              >
                <Column
                  field="latitude"
                  header="Latitude"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
                <Column
                  field="longitude"
                  header="Longitude"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
                <Column
                  sortable
                  field="speed"
                  header="Speed"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                />
                <Column
                  sortable
                  dataType="date"
                  field="dateTime"
                  header="Time"
                  className="text-sm 5xl:text-base"
                  bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                  headerClassName="!bg-white !text-brown-500 !border-brown-500"
                  body={tripHistoryDateTimeTemplate}
                />
              </DataTable>
            </SplitterPanel>
            <SplitterPanel size={80}>
              {isTripHistoryLoading ? (
                <div className="flex justify-center h-full align-middle">
                  <span className="inline-flex items-center">
                    <CogIcon
                      className="w-6 h-6 mr-2 -ml-1 animate-spin"
                      aria-hidden="true"
                    />
                    {"Downloading Coordinates..."}
                  </span>
                </div>
              ) : (
                <TripHistoryMap {...{ tripCoordinatesArray, markersArray }} />
              )}
            </SplitterPanel>
          </Splitter>
        ) : (
          <DataTable
            size="small"
            stripedRows
            value={geoTabTrips}
            paginator
            rows={10}
            dataKey="id"
            sortField="start"
            sortOrder={-1}
            paginatorTemplate={newPaginationTemplate}
            responsiveLayout="scroll"
            className="custom-table"
            emptyMessage="No available records"
            loading={geoTabGetTripDetailsIsSubmitting}
            style={{ fontFamily: "Inter" }}
          >
            <Column
              sortable
              field="distance"
              header="Distance"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={tripDistanceTemplate}
            />
            <Column
              sortable
              field="drivingDuration"
              header="Driving Duration"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
            />
            <Column
              sortable
              dataType="date"
              field="start"
              header="Start"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={tripStartTemplate}
            />
            <Column
              sortable
              dataType="date"
              field="stop"
              header="Stop"
              className="text-sm 5xl:text-base"
              bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
              headerClassName="!bg-white !text-brown-500 !border-brown-500"
              body={tripStopTemplate}
            />
            {orgName !== "BOUNCIE" && (
              <Column
                sortable
                dataType="date"
                field="nextTripStart"
                header="Next Trip Start"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={nextTripStartTemplate}
              />
            )}
            {orgName !== "BOUNCIE" && (
              <Column
                header="Trip Details"
                className="text-sm 5xl:text-base"
                bodyClassName="text-brown-500 !py-1 text-sm 5xl:text-base"
                headerClassName="!bg-white !text-brown-500 !border-brown-500"
                body={getTripHistoryTemplate}
              />
            )}
          </DataTable>
        )}
      </div>
    </Dialog>
  );
}
