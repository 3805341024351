/** @format */

import { ENUM_CARD_SHARING, ENUM_CUSTOMER_TYPE } from "@prisma/client";
import {
  useReadAccountControlSetting,
  useReadAdminCardSetting,
  useReadCardPolicy,
} from "@roadflex/react-hooks";
import { CardMerchants, ChildFeatureType, UserAuthType } from "@roadflex/types";
import { useEffect, useState } from "react";
import { Loader } from "../../../../src/loader";
import { PolicyUI } from "./policy.ui";

type PolicyProps = {
  readCurrentUserAuth: UserAuthType;
  policyId: string;
  childFeatures: ChildFeatureType[];
};
export const Policy = ({
  readCurrentUserAuth,
  policyId,
  childFeatures,
}: PolicyProps) => {
  const { adminCardSettingData } = useReadAdminCardSetting();
  const [submittingRequest, setsubmittingRequest] = useState(false);
  const [editCardRules, setEditCardRules] = useState(false);
  const [editLimits, setEditLimits] = useState(false);
  const { data: readCardPolicy, loading } = useReadCardPolicy(policyId);
  const { accountControlSettingData } = useReadAccountControlSetting();
  const [defaultMechants, setdefaultMechants] = useState<CardMerchants[]>([]);
  const tabs = [
    { id: 1, name: "Card Rules", current: true },
    { id: 2, name: "Spend Limits", current: false },
    { id: 3, name: "Dynamic Purchase Controls", current: false },
  ];
  useEffect(() => {
    if (
      readCardPolicy?.readCardPolicy?.policy &&
      readCardPolicy?.readCardPolicy?.policy[0]?.merchants?.length > 0
    ) {
      setdefaultMechants(
        JSON.parse(
          JSON.stringify(readCardPolicy?.readCardPolicy?.policy[0].merchants),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readCardPolicy?.readCardPolicy?.policy?.length]);
  if (
    loading ||
    !readCardPolicy?.readCardPolicy?.policy ||
    defaultMechants.length === 0
  ) {
    return <Loader></Loader>;
  }

  return (
    <PolicyUI
      {...{
        cardRuleData: readCardPolicy?.readCardPolicy?.policy[0],
        merchantOriginal: defaultMechants,
        tabs,
        adminCardSettingData: adminCardSettingData?.readAdminCardSetting
          ?.data[0] || {
          dailyLimit: 0,
          transactionLimit: 0,
          categories: "",
          customerType: ENUM_CUSTOMER_TYPE.PREPAID_PLUS,
          restrictedMerchants: "",
        },
        submittingRequest,
        editLimits,
        setEditLimits,
        editCardRules,
        setEditCardRules,
        setsubmittingRequest,
        childFeatures,
        cardSharingControls:
          accountControlSettingData?.readAccountControlSettings
            ?.cardSharingControls || ENUM_CARD_SHARING.NONE,
      }}
    />
  );
};
