/** @format */

import { useMutation } from "@apollo/client";
import {
  ENUM_CARD_SHARING,
  ENUM_CARD_TYPE,
  ENUM_CUSTOMER_TYPE,
  ENUM_DELIVERY_STATUS,
  ENUM_DYNAMIC_PURCHASE_CONTROL_SETTING,
  ENUM_PLATFORM_TYPE,
  ENUM_TELEMATICS_SECURITY,
} from "@prisma/client";
import { cardDetailsUpdateSchema, DAYS_OF_WEEK } from "@roadflex/constants";
import {
  ORDER_PHYSICAL_CARD,
  READ_APP_CARD_DATA,
  UPDATE_CARD_DETAILS,
} from "@roadflex/graphql";
import {
  useReadAccountControlSetting,
  useReadAdminCardSetting,
  useReadAppCardData,
  useReadFilterOptions,
} from "@roadflex/react-hooks";
import {
  Card,
  CardDetails,
  CardMerchants,
  CardRestrictionFormikValues,
  CardShortened,
  ChildFeatureType,
  FleetDriverFilterType,
  FleetFilterType,
  OperationalHours,
  ParameterFilterType,
  UserAuthType,
} from "@roadflex/types";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { Loader } from "../../loader";
import Redirect from "../../redirect/redirect";
import { Toast } from "../../toast-message/toast";
import { CardDetailsPageUI } from "./card-details-page.ui";

interface CardsProps {
  readCurrentUserAuth: UserAuthType;
  childFeatures: ChildFeatureType[];
  loading: boolean;
  cardId: string;
  refetch: () => void;
  cardTags: ParameterFilterType[];
}

export const CardDetailsPage = ({
  readCurrentUserAuth,
  childFeatures,
  loading,
  cardId,
  refetch,
  cardTags,
}: CardsProps) => {
  const [submittingRequest, setsubmittingRequest] = useState(false);
  const [submittingCardOrderRequest, setsubmittingCardOrderRequest] =
    useState(false);
  const [showConfirmation, setConfirmation] = useState(false);
  const [editLimits, setEditLimits] = useState(false);
  const [editCardRules, setEditCardRules] = useState(false);
  const [editType, setEditType] = useState("");
  const [editedOperationalHours, setEditedOperationalHours] = useState<
    OperationalHours[]
  >([]);
  const [tabs, setTabs] = useState([
    { id: 1, name: "Card Rules", current: true },
    { id: 2, name: "Spend Limits", current: false },
  ]);
  const [variables, setVariables] = useState({ var1: 0, var2: 0, var3: 0 });
  const [overallToggle, setOverallToggle] = useState(false);
  const {
    adminCardSettingData,
    adminCardSettingRefetch,
    adminCardSettingLoading,
  } = useReadAdminCardSetting();
  const { accountControlSettingData } = useReadAccountControlSetting();
  const {
    readAppCardData,
    loading: readAppCardDataLoading,
    refetch: readAppCardDataRefetch,
  } = useReadAppCardData(true, false, true, "Full", cardId);

  const { filterOptions, loading: filterLoading } = useReadFilterOptions();

  useEffect(() => {
    const foundCard: Card = readAppCardData?.cards?.find(
      (card: Card) => card.id === cardId,
    );

    let updatedTabs: { id: number; name: string; current: boolean }[] = [];

    if (foundCard) {
      if (foundCard.platform === ENUM_PLATFORM_TYPE.STRIPE) {
        if (foundCard.cardType === ENUM_CARD_TYPE.VIRTUAL) {
          updatedTabs = [
            { id: 0, name: "Card Information", current: true },
            { id: 1, name: "Card Rules", current: false },
            { id: 2, name: "Spend Limits", current: false },
          ];
        } else if (foundCard.cardType === ENUM_CARD_TYPE.PHYSICAL) {
          updatedTabs = [
            { id: 0, name: "Pin Management", current: true },
            { id: 1, name: "Card Rules", current: false },
            { id: 2, name: "Spend Limits", current: false },
          ];
        }
      } else if (foundCard.platform === ENUM_PLATFORM_TYPE.HIGHNOTE) {
        updatedTabs = [
          { id: 0, name: "Card Information", current: true },
          { id: 1, name: "Card Rules", current: false },
          { id: 2, name: "Spend Limits", current: false },
        ];
      }
    }

    // Check for dynamic purchase controls linked to card
    if (
      accountControlSettingData?.readAccountControlSettings
        ?.dynamicPurchaseControlsLinkedTo ===
      ENUM_DYNAMIC_PURCHASE_CONTROL_SETTING.CARD
    ) {
      updatedTabs.push({
        id: 3,
        name: "Dynamic Purchase Controls",
        current: false,
      });
    }

    setTabs(updatedTabs);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readAppCardData, accountControlSettingData]);

  const [defaultMerchants, setdefaultMerchants] = useState<CardMerchants[]>([]);

  useEffect(() => {
    if (
      readAppCardData?.cards &&
      readAppCardData?.cards.length > 0 &&
      readAppCardData?.cards.find((card: Card) => card.id === cardId)?.merchants
        .length > 0
    ) {
      setdefaultMerchants(
        JSON.parse(
          JSON.stringify(
            readAppCardData?.cards.find((card: Card) => card.id === cardId)
              ?.merchants,
          ),
        ),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readAppCardData?.cards?.length]);

  let cardDetailsObj: Card = readAppCardData?.cards[0];
  const cardOperationalHours = cardDetailsObj?.operationalHours || [];

  const initializeEditedOperationalHours = (
    opHours: OperationalHours[],
  ): OperationalHours[] => {
    return DAYS_OF_WEEK.map((day) => {
      const existingOpHours = opHours.find((oh) => oh.dayOfWeek === day);
      if (existingOpHours) {
        existingOpHours.isEnabled = true;
        return existingOpHours;
      }
      return {
        isEnabled: false,
        dayOfWeek: day,
        startTime: "",
        endTime: "",
      };
    });
  };

  useEffect(() => {
    setEditedOperationalHours(
      initializeEditedOperationalHours(cardDetailsObj?.operationalHours || []),
    );
  }, [cardDetailsObj?.operationalHours]);

  readAppCardData?.cards.forEach((card: Card) => {
    if (card.id === cardId) {
      cardDetailsObj = card;
    }
  });

  const [updateCardDetailsRequest] = useMutation<{
    updateCardDetails: {
      message: string;
      code: string;
      cardUpdated: boolean;
    };
  }>(UPDATE_CARD_DETAILS, {
    refetchQueries: [READ_APP_CARD_DATA],
  });

  const [orderPhysicalCardRequest] = useMutation<{
    orderPhysicalCardFromVirtualCard: {
      message: string;
      code: string;
    };
  }>(ORDER_PHYSICAL_CARD);

  const handlePhysicalCardOrder = () =>
    // deliveryType: string,
    // requireSignature: boolean,
    {
      setsubmittingCardOrderRequest(true);
      orderPhysicalCardRequest({
        variables: {
          data: {
            id: cardDetailsObj.id,
            userId: readCurrentUserAuth.primaryOwnerId,
            deliveryType: "STANDARD",
            requireSignature: false,
          },
        },
      })
        .then(({ data }) => {
          if (data?.orderPhysicalCardFromVirtualCard?.code === "200") {
            Toast({
              type: "success",
              message: data?.orderPhysicalCardFromVirtualCard?.message,
            });
          }
          setsubmittingCardOrderRequest(false);
          readAppCardDataRefetch();
        })
        .catch((err) => {
          Toast({
            type: "error",
            message: err.message,
          });
          setsubmittingCardOrderRequest(false);
        });
    };

  let numberActiveCards = 0;
  readAppCardData?.cards?.forEach((card: Card) => {
    if (
      card?.cardShipping[0]?.deliveryStatus ===
        ENUM_DELIVERY_STATUS.DELIVERED ||
      card?.cardType === "VIRTUAL"
    ) {
      if (card?.status === "active") {
        numberActiveCards += 1;
      }
    }
  });

  const handleCardUpdate = (cardDetails: CardDetails) => {
    setsubmittingRequest(true);
    const {
      operationalHours,
      merchants,
      driverId,
      vehicleId,
      status,
      monthly,
      weekly,
      daily,
      transaction,
      enableOperationalHours,
      blockState,
      smsSecurity,
      fuelTypeSecurity,
      tankCapacitySecurity,
      telematicsSecurity,
      odometerSecurity,
      requireJobID,
      suspiciousFuelingSecurity,
      enableSmsReceiptUpload,
      flaggedMerchantCategories,
    } = cardDetails;
    const identifier = cardDetails?.cardId;
    const newMerchants: CardMerchants[] = [];
    if (merchants) {
      for (let i = 0; i < merchants?.length; i++) {
        const newObj = merchants[i];
        Object.keys(newObj).forEach((key) => {
          if (
            newObj[key as keyof typeof newObj] === null ||
            key === "__typename"
          ) {
            delete newObj[key as keyof typeof newObj];
          }
        });
        newMerchants.push(newObj);
      }
    }
    updateCardDetailsRequest({
      variables: {
        data: {
          requestType: editType,
          cardId: identifier,
          operationalHours: operationalHours,
          enableOperationalHours: enableOperationalHours,
          blockState: blockState,
          driverId: driverId,
          vehicleId: vehicleId,
          status: status,
          monthly: monthly,
          weekly: weekly,
          daily: daily,
          transaction: transaction,
          smsSecurity: smsSecurity,
          fuelTypeSecurity: fuelTypeSecurity,
          tankCapacitySecurity: tankCapacitySecurity,
          telematicsSecurity: telematicsSecurity,
          odometerSecurity: odometerSecurity,
          requireJobID: requireJobID,
          suspiciousFuelingSecurity: suspiciousFuelingSecurity,
          merchants: newMerchants,
          enableSmsReceiptUpload: enableSmsReceiptUpload,
          flaggedMerchantCategories: flaggedMerchantCategories,
        },
      },
    })
      .then(({ data }) => {
        if (
          data?.updateCardDetails?.code === "200" &&
          data?.updateCardDetails?.cardUpdated
        ) {
          Toast({
            type: "success",
            message: "Card updated successfully",
          });
          setEditLimits(false);
          setEditCardRules(false);
          setEditType("");
        } else {
          Toast({
            type: "error",
            message: data?.updateCardDetails?.message || "",
          });
        }
        readAppCardDataRefetch();
        setsubmittingRequest(false);
      })
      .catch((err) => {
        console.error(err);
        setsubmittingRequest(false);
        Toast({
          type: "error",
          message: err.message,
        });
      });
  };

  const adminCardSettingFinalData = adminCardSettingData?.readAdminCardSetting
    .data[0] || {
    dailyLimit: 0,
    transactionLimit: 0,
    categories: "",
    customerType: ENUM_CUSTOMER_TYPE.PREPAID_PLUS,
    restrictedMerchants: "",
  };

  let vehiclesList = filterOptions?.transactionFilterOptions?.vehicles || [];
  let driversList = filterOptions?.transactionFilterOptions?.drivers || [];

  vehiclesList = vehiclesList?.map((vehicle: FleetFilterType) => {
    const newVehicle = { ...vehicle };
    newVehicle.createdAt = new Date(vehicle.createdAt);
    return newVehicle;
  });

  driversList = driversList?.map((driver: FleetDriverFilterType) => {
    const newDriver = { ...driver };
    newDriver.createdAt = new Date(driver.createdAt);
    return newDriver;
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    resetForm,
    validateForm,
    setFieldTouched,
    ...rest
  } = useFormik<CardRestrictionFormikValues>({
    enableReinitialize: true,
    initialValues: {
      flaggedMerchantCategories:
        cardDetailsObj?.flaggedMerchantCategories || [],
      driverId: cardDetailsObj?.fleetDriver[0]?.id || "",
      isPhoneVerified: cardDetailsObj?.fleetDriver[0]?.isPhoneVerified || false,
      vehicleId: cardDetailsObj?.fleet[0]?.id || "",
      enableOperationalHours: cardDetailsObj?.enableOperationalHours || false,
      blockState: cardDetailsObj?.blockState,
      dailyLimit:
        (cardDetailsObj?.cardSpending.find(
          (data) => data.interval === "daily" && !data?.category,
        )?.amount || 0) / 100 ||
        adminCardSettingFinalData?.dailyLimit / 100 ||
        "",
      weeklyLimit:
        (cardDetailsObj?.cardSpending.find(
          (data) => data.interval === "weekly" && !data?.category,
        )?.amount || 0) / 100 || "",
      monthlyLimit:
        (cardDetailsObj?.cardSpending.find(
          (data) => data.interval === "monthly",
        )?.amount || 0) / 100 || "",
      transactionLimit:
        (cardDetailsObj?.cardSpending.find(
          (data) => data.interval === "per_authorization",
        )?.amount || 0) / 100 ||
        adminCardSettingFinalData?.transactionLimit / 100 ||
        "",
      status: cardDetailsObj?.status || "inactive",
      smsSecurity: cardDetailsObj?.smsSecurity || false,
      fuelTypeSecurity: cardDetailsObj?.fuelTypeSecurity || false,
      tankCapacitySecurity: cardDetailsObj?.tankCapacitySecurity || false,
      telematicsSecurity:
        cardDetailsObj?.telematicsSecurity || ENUM_TELEMATICS_SECURITY.OFF,
      enableSmsReceiptUpload: cardDetailsObj?.enableSmsReceiptUpload || false,
      odometerSecurity: cardDetailsObj?.odometerSecurity || false,
      requireJobID: cardDetailsObj?.requireJobID || false,
      suspiciousFuelingSecurity:
        cardDetailsObj?.suspiciousFuelingSecurity || false,
      merchants: defaultMerchants,
    },
    validationSchema: cardDetailsUpdateSchema(
      adminCardSettingFinalData,
      vehiclesList,
      driversList,
      accountControlSettingData?.readAccountControlSettings?.cardSharingControls
        ? accountControlSettingData?.readAccountControlSettings
            ?.cardSharingControls
        : ENUM_CARD_SHARING.NONE,
    ),
    onSubmit: async (value) => {
      const operationalHoursList = editedOperationalHours
        .filter((oh) => {
          return oh.isEnabled && oh.startTime && oh.endTime;
        })
        .map((filteredOh) => {
          return {
            dayOfWeek: filteredOh.dayOfWeek,
            startTime: filteredOh.startTime,
            endTime: filteredOh.endTime,
          };
        });

      const enableOpHours = !operationalHoursList.length
        ? false
        : value.enableOperationalHours;

      const data = {
        cardId: cardDetailsObj?.id,
        driverId: value.driverId,
        vehicleId: value.vehicleId,
        status: value.status,
        monthly: value.monthlyLimit ? Number(value.monthlyLimit) * 100 : 0,
        weekly: value.weeklyLimit ? Number(value.weeklyLimit) * 100 : 0,
        daily: value.dailyLimit ? Number(value.dailyLimit) * 100 : 0,
        transaction: value.transactionLimit
          ? Number(value.transactionLimit) * 100
          : 0,
        operationalHours: operationalHoursList,
        blockState: value?.blockState,
        enableSmsReceiptUpload: value.enableSmsReceiptUpload,
        enableOperationalHours: enableOpHours,
        smsSecurity: values.smsSecurity,
        fuelTypeSecurity: values.fuelTypeSecurity,
        tankCapacitySecurity: values.tankCapacitySecurity,
        telematicsSecurity: values.telematicsSecurity,
        suspiciousFuelingSecurity: values.suspiciousFuelingSecurity,
        odometerSecurity: values.odometerSecurity,
        requireJobID: values.requireJobID,
        merchants: values.merchants,
        flaggedMerchantCategories: values.flaggedMerchantCategories,
      };
      if (handleCardUpdate) {
        handleCardUpdate(data);
      }
    },
  });

  if (loading || readAppCardDataLoading || adminCardSettingLoading) {
    return <Loader />;
  }

  const updateCardStatus = (card: Card) => {
    const { id, status } = card;
    const newStatus = status === "inactive" ? "active" : "inactive";
    setsubmittingRequest(true);
    updateCardDetailsRequest({
      variables: {
        data: {
          cardId: id,
          requestType: "STATUSCHANGE",
          status: newStatus,
        },
      },
    })
      .then(({ data }) => {
        if (
          data?.updateCardDetails?.code === "200" &&
          data?.updateCardDetails?.cardUpdated
        ) {
          Toast({
            type: "success",
            message: "Card status updated successfully",
          });
        } else {
          Toast({
            type: "error",
            message: data?.updateCardDetails?.message || "",
          });
        }
        refetch();
        setsubmittingRequest(false);
      })
      .catch((err) => {
        console.error(err);
        setsubmittingRequest(false);
        Toast({
          type: "error",
          message: err.message || "",
        });
      });
  };

  const proceedToHandle = (cardStatus: string) => {
    if (cardStatus === "inactive") {
      setConfirmation(false);
      return false;
    }
    const cardsCharged = readAppCardData.cardsCharged;
    const makingActive = 1;
    if (makingActive + numberActiveCards <= cardsCharged) {
      setConfirmation(false);
      return false;
    }
    setVariables({
      var1: cardsCharged,
      var2: makingActive,
      var3: makingActive + numberActiveCards - cardsCharged,
    });
    setConfirmation(true);
    return true;
  };

  const currentVehiclesSet = new Set<string>();
  const currentDriversSet = new Set<string>();

  if (cardDetailsObj) {
    const newCardObj: CardShortened = {
      ...cardDetailsObj,
      driverName:
        (cardDetailsObj?.fleetDriver &&
          cardDetailsObj?.fleetDriver?.length > 0 &&
          cardDetailsObj?.fleetDriver[0]?.name) ||
        "-",
      driverEmail:
        (cardDetailsObj?.fleetDriver &&
          cardDetailsObj?.fleetDriver?.length > 0 &&
          cardDetailsObj?.fleetDriver[0]?.email) ||
        "-",
      vehicleName:
        (cardDetailsObj?.fleet &&
          cardDetailsObj?.fleet?.length > 0 &&
          cardDetailsObj?.fleet[0]?.fleetName) ||
        "-",
    };
    if (newCardObj.fleet) {
      currentVehiclesSet.add(newCardObj?.fleet[0]?.fleetName);
    }
    if (newCardObj.fleetDriver) {
      currentDriversSet.add(newCardObj?.fleetDriver[0]?.name);
    }
  } else {
    Toast({
      type: "error",
      message: "Card not found",
    });
    return <Redirect path="/cards"></Redirect>;
  }

  const handleOverallToggle = (checked: boolean) => {
    setOverallToggle(checked);
    const updatedMerchants = (values?.merchants || []).map((merchant) => ({
      ...merchant,
      toggleOn: checked,
    }));
    setFieldValue("merchants", updatedMerchants);
  };

  const handleCancel = () => {
    setEditedOperationalHours(
      initializeEditedOperationalHours(cardOperationalHours),
    );
    resetForm();
    setFieldValue(
      "merchants",
      JSON.parse(JSON.stringify(cardDetailsObj?.merchants)),
    );
    setEditLimits(false);
    setEditCardRules(false);
    setOverallToggle(false);
  };

  const handleMerchantChange = <K extends keyof CardMerchants>(
    merchantType: CardMerchants,
    value: CardMerchants[K],
    updateField: K,
  ) => {
    const newObject: CardMerchants[] = JSON.parse(
      JSON.stringify(values.merchants),
    );

    if (newObject) {
      let index = newObject?.findIndex((val) => val.name === merchantType.name);
      newObject[index][updateField] = value;

      if (merchantType.name === "auto_body_repair_shops") {
        index = newObject?.findIndex(
          (val) => val.name === "auto_service_shops",
        );
        newObject[index][updateField] = value;
      }

      setFieldValue("merchants", newObject);
    }
  };

  const handleCheckboxChange = (dayOfWeek: string, checked: boolean) => {
    setEditedOperationalHours((prev) =>
      prev.map((oh) =>
        oh.dayOfWeek === dayOfWeek
          ? {
              ...oh,
              startTime: checked ? oh.startTime : "",
              endTime: checked ? oh.endTime : "",
              isEnabled: checked,
            }
          : oh,
      ),
    );
  };

  const handleSubmitManually = () => {
    validateForm()
      .then(() => {
        Object.keys(values).forEach((field) => {
          setFieldTouched(field, true);
        });
        if (Object.keys(errors).length === 0) {
          handleSubmit();
        } else {
          // toast.error(Object.values(errors)[0]);
          Toast({
            type: "error",
            message: "There are some errors on this page",
          });
        }
      })
      .catch((err) => {
        Toast({
          type: "error",
          message: err.message,
        });
      });
  };

  return (
    <CardDetailsPageUI
      {...{
        cardDetailsObj,
        defaultMerchants,
        updateCardStatus,
        editLimits,
        setEditLimits,
        editCardRules,
        setEditCardRules,
        variables,
        adminCardSettingData: adminCardSettingFinalData,
        editType,
        setEditType,
        showConfirmation,
        setConfirmation,
        submittingRequest,
        submittingCardOrderRequest,
        handleCardUpdate,
        driversList,
        vehiclesList,
        proceedToHandle,
        filterLoading,
        tabs,
        childFeatures,
        handlePhysicalCardOrder,
        editedOperationalHours,
        setEditedOperationalHours,
        handleSubmitManually,
        overallToggle,
        handleOverallToggle,
        handleCancel,
        handleMerchantChange,
        handleCheckboxChange,
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        touched,
        errors,
        setFieldValue,
        resetForm,
        validateForm,
        setFieldTouched,
        ...rest,
        cardTags,
        cardSharingControls:
          accountControlSettingData?.readAccountControlSettings
            ?.cardSharingControls || ENUM_CARD_SHARING.NONE,
      }}
    />
  );
};
