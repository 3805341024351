/** @format */

import { useImportedTransactionList } from "@roadflex/react-hooks";
import { ExtendedTransactionType, UserAuthType } from "@roadflex/types";
import TransactionsImportedUI from "./transactions-imported.ui";

type TransactionsImportedProps = {
  readCurrentUserAuth: UserAuthType;
  loading: boolean;
};

export const TransactionsImported = ({
  readCurrentUserAuth,
  loading,
}: TransactionsImportedProps) => {
  const transactionList: ExtendedTransactionType[] = [];
  const customerType = readCurrentUserAuth?.fuelCard?.customerType;

  const { transactionData, transactionRefetch, transactionLoading } =
    useImportedTransactionList();

  transactionData?.readImportedTransactions?.transactions?.forEach((item) => {
    const newTransactionObj = {
      ...item,
      date: new Date(item?.authorizationDate),
      driver: item?.driverName || "-",
      vehicle: item?.vehicleName || "-",
      status: "Approved",
      lastFour: item?.card?.lastFour || "-",
    };
    transactionList.push(newTransactionObj);
  });

  return (
    <TransactionsImportedUI
      {...{
        transactionList,
        transactionLoading,
        transactionRefetch,
        readCurrentUserAuth,
        customerType,
      }}
    />
  );
};
