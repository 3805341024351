/** @format */

import { CheckCircleIcon, ShieldCheckIcon } from "@heroicons/react/24/solid";
import { usePlatformDetect } from "@roadflex/react-hooks-www";
import {
  AddressType,
  BusinessType,
  TermsOfServiceValues,
  UserOnboardType,
} from "@roadflex/types";
import classNames from "classnames";
import { FormikProps } from "formik";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../buttons";
import { RegistrationConfirmationModal } from "../modals";
import { OnboardBannerComponent } from "./common-banner-component";

interface Props extends FormikProps<TermsOfServiceValues> {
  onJumpToStep: (step: number) => void;
  onConfirm: () => void;
  confirming: boolean;
  showRegistrationConfirmationModal: boolean;
  setShowRegistrationConfirmationModal: (
    showRegistrationConfirmationModal: boolean,
  ) => void;
  readCurrentUserOnboard: UserOnboardType;
  business: BusinessType;
  addresses: AddressType[];
  isSubmittingApplication: boolean;
  onPreviousStep: () => void;
}

const TermsOfServiceUI = ({
  onJumpToStep,
  onConfirm,
  confirming,
  showRegistrationConfirmationModal,
  setShowRegistrationConfirmationModal,
  readCurrentUserOnboard,
  business,
  addresses,
  values,
  handleChange,
  handleSubmit,
  setFieldValue,
  setFieldTouched,
  isSubmitting,
  handleBlur,
  errors,
  touched,
  isSubmittingApplication,
  onPreviousStep,
  ...rest
}: Props) => {
  const { isMobile } = usePlatformDetect();
  return (
    <div
      className={classNames(
        isMobile() ? "flex-col" : "flex-row",
        "flex w-full h-full min-h-screen",
      )}
    >
      <OnboardBannerComponent header="Terms of Service" />
      <div className="flex flex-col w-full px-6 py-8 md:p-16">
        <form className="space-y-6">
          <div className="flex justify-between" role="group">
            <label
              htmlFor="authorizedRepresentative"
              className="mr-3 flex max-w-[600px] relative"
            >
              <span className="absolute text-red-500 left-[-15px]">*</span>I am
              an authorized representative with authority to apply on behalf of
              the business and that the information provided is accurate.
            </label>
            <div className="flex items-center">
              <input
                id="authorizedRepresentative"
                name="authorizedRepresentative"
                type="checkbox"
                disabled={false}
                checked={values.authorizedRepresentative}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-4 h-4 text-orange-600 border-0 cursor-pointer focus:ring-offset-0 focus:ring-0 focus:outline-none focus:shadow-none"
              />
            </div>
          </div>
          <div className="flex justify-between" role="group">
            <label
              htmlFor="agreeToRoadFlexTerms"
              className="mr-3 max-w-[600px] relative "
            >
              <span className="absolute text-red-500 left-[-15px]">*</span>I
              agree to the{" "}
              <span
                className="underline hover:cursor-pointer"
                onClick={() => {
                  setShowRegistrationConfirmationModal(true);
                }}
              >
                RoadFlex Card Program Terms
              </span>
            </label>
            <div className="flex items-center">
              <input
                id="agreeToRoadFlexTerms"
                name="agreeToRoadFlexTerms"
                type="checkbox"
                disabled={false}
                checked={values.agreeToRoadFlexTerms}
                onChange={handleChange}
                onBlur={handleBlur}
                className="w-4 h-4 text-orange-600 border-0 cursor-pointer focus:ring-offset-0 focus:ring-0 focus:outline-none focus:shadow-none"
              />
            </div>
          </div>
        </form>
        <div className="flex items-center justify-center px-4 py-1 my-16 text-sm text-center mx-auto max-w-[500px] w-full self-center bg-green-100 rounded-full">
          {" "}
          <CheckCircleIcon className="w-4 h-4 mr-1 text-green-900"></CheckCircleIcon>
          Applying won't affect your personal credit score
        </div>
        <div className="">
          <div>Questions?</div>
          <div className="mt-2 text-sm italic">
            Contact our support team at RoadFlex at (877) 870-7440 or email{" "}
            <a
              className="text-brown-800"
              target="_blank"
              rel="noreferrer"
              href="mailto:support@roadflex.com"
            >
              support@roadflex.com
            </a>
            .
          </div>
        </div>
        <div className="mt-6">
          <Button
            variant={ButtonVariant.Orange}
            onClick={() => handleSubmit()}
            className="w-full"
            disabled={Object.keys(errors).length > 0 || isSubmittingApplication}
            loading={isSubmittingApplication}
          >
            Submit Application
          </Button>
        </div>
        <div>
          <div className="flex items-center justify-center mt-1 text-xs text-center md:text-sm">
            <ShieldCheckIcon className="w-4 h-4 mr-1"></ShieldCheckIcon>
            We securely store and encrypt your personal information
          </div>
          <Button
            type={ButtonType.Button}
            variant={ButtonVariant.Transparent}
            className="pl-0"
            size={ButtonSize.Small}
            onClick={onPreviousStep}
          >
            &#60;&#60; Go back
          </Button>
        </div>

        <RegistrationConfirmationModal
          confirm={() => {
            setFieldValue("agreeToRoadFlexTerms", true);
            setShowRegistrationConfirmationModal(false);
          }}
          submitting={confirming}
          showRegistrationConfirmationModal={showRegistrationConfirmationModal}
          setShowRegistrationConfirmationModal={
            setShowRegistrationConfirmationModal
          }
          business={business}
          addresses={addresses}
          readCurrentUserOnboard={readCurrentUserOnboard}
        />
      </div>
    </div>
  );
};

export default TermsOfServiceUI;
