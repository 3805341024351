/** @format */

import { LockClosedIcon } from "@heroicons/react/24/outline";
import { ShieldCheckIcon } from "@heroicons/react/24/solid";
import { ENUM_ADDRESS_TYPE } from "@prisma/client";
import {
  BUSINESS_TYPE,
  COMPANY_TYPE,
  dollarFormatter,
} from "@roadflex/constants";
import { usePlatformDetect } from "@roadflex/react-hooks-www";
import {
  AdditionalBusinessInfoType,
  AddressType,
  BusinessType,
  UserOnboardType,
} from "@roadflex/types";
import { humanizeEnum } from "@roadflex/utils";
import classNames from "classnames";
import { Button, ButtonSize, ButtonType, ButtonVariant } from "../buttons";
import { OnboardBannerComponent } from "./common-banner-component";

type Props = {
  business: BusinessType;
  addresses: AddressType[];
  additionalBusinessInfo: AdditionalBusinessInfoType;
  onJumpToStep: (step: number) => void;
  onConfirm: () => void;
  confirming: boolean;
  showRegistrationConfirmationModal: boolean;
  setShowRegistrationConfirmationModal: (
    showRegistrationConfirmationModal: boolean,
  ) => void;
  readCurrentUserOnboard: UserOnboardType;
  nextStep: (jumpStepBy?: number) => void;
  isSubmittingOnboardStatus: boolean;
  isSameAddress: boolean;
  onPreviousStep: () => void;
};

const ReviewUI = ({
  business,
  addresses,
  additionalBusinessInfo,
  onJumpToStep,
  onConfirm,
  confirming,
  showRegistrationConfirmationModal,
  setShowRegistrationConfirmationModal,
  readCurrentUserOnboard,
  nextStep,
  isSubmittingOnboardStatus,
  isSameAddress,
  onPreviousStep,
}: Props) => {
  const businessAddress = addresses.find(
    (value) => value.type === ENUM_ADDRESS_TYPE.PERMANENT,
  );
  const deliveryAddress = addresses.find(
    (value) => value.type === ENUM_ADDRESS_TYPE.DELIVERY,
  );
  // const homeAddress = addresses.find(
  //   (value) => value.type === ENUM_ADDRESS_TYPE.HOME,
  // );
  const { isMobile } = usePlatformDetect();
  return (
    <div
      className={classNames(
        isMobile() ? "flex-col" : "flex-row",
        "flex w-full h-full min-h-screen",
      )}
    >
      <OnboardBannerComponent header="Review Your Information" />
      <div className="flex flex-col w-full px-4 py-8 md:p-16">
        <div className="w-full space-y-2">
          <div>
            <div className="flex flex-row items-center justify-between">
              <div className="">Business</div>
              <Button
                variant={ButtonVariant.Transparent}
                className="underline-offset-2 underline !px-0"
                onClick={() => {
                  onJumpToStep(2);
                }}
              >
                Edit
              </Button>
            </div>
            <div className="w-full p-4 text-sm bg-white rounded-md">
              <span className=""> Name:</span>{" "}
              <span className="capitalize">{business.title}</span> <br />
              <span className=""> Business Type:</span>{" "}
              {BUSINESS_TYPE[business.type] || humanizeEnum(business.type)}{" "}
              <br />
              {business.companyType && (
                <>
                  <span className=""> Company Type:</span>{" "}
                  {COMPANY_TYPE[business.companyType] ||
                    humanizeEnum(business.companyType)}{" "}
                  <br />
                </>
              )}
              <span className=""> Name on Cards:</span> {business.cardName}{" "}
              <br />
              <span className=""> Years of Operation:</span>{" "}
              {business.yearsOfOperation} <br />
              <span className=""> EIN:</span> {business.ein} <br />
            </div>
          </div>
          <div>
            <div className="flex flex-row items-center justify-between">
              <div className="">Additional Business Information</div>
              <Button
                variant={ButtonVariant.Transparent}
                className="underline-offset-2 underline !px-0"
                onClick={() => {
                  onJumpToStep(3);
                }}
              >
                Edit
              </Button>
            </div>
            <div className="w-full p-4 text-sm bg-white rounded-md">
              <span className=""> Fuel and maintenance spend per week:</span>{" "}
              {dollarFormatter(
                (additionalBusinessInfo?.weeklyfuelSpends as number) / 100,
              )}
              <br />
              <span className="">Annual Business Revenue:</span>{" "}
              {dollarFormatter(
                (additionalBusinessInfo?.annualBusinessRevenue as number) / 100,
              )}{" "}
              <br />
              <span className="">Number of Vehicles:</span>{" "}
              {additionalBusinessInfo?.fleetNum} <br />
              {business.mc && (
                <>
                  <span className="">MC Number</span>: {business.mc} <br />
                </>
              )}
              {business.dot && (
                <>
                  <span className="">DOT Number</span>: {business.dot} <br />
                </>
              )}
            </div>
          </div>
          <div>
            <div className="flex flex-row items-center justify-between">
              <div className="">Business Address</div>
              <Button
                variant={ButtonVariant.Transparent}
                className="underline-offset-2 underline !px-0"
                onClick={() => {
                  onJumpToStep(2);
                }}
              >
                Edit
              </Button>
            </div>
            <div className="w-full p-4 text-sm bg-white rounded-md">
              {businessAddress?.streetAddress}, <br />
              {businessAddress?.streetDetails && (
                <div>{businessAddress?.streetDetails},</div>
              )}
              {businessAddress?.city}, {businessAddress?.state}, <br />
              {businessAddress?.zip}
            </div>
          </div>
          {!isSameAddress && (
            <div>
              <div className="flex flex-row items-center justify-between">
                <div className="">Shipping Address</div>
                <Button
                  variant={ButtonVariant.Transparent}
                  className="underline-offset-2 underline text-white !px-0"
                  onClick={() => {
                    onJumpToStep(4);
                  }}
                >
                  Edit
                </Button>
              </div>
              <div className="w-full p-4 text-sm bg-white rounded-md">
                {deliveryAddress?.streetAddress}, <br />
                {deliveryAddress?.streetDetails && (
                  <div>{deliveryAddress?.streetDetails},</div>
                )}
                {deliveryAddress?.city}, {deliveryAddress?.state}, <br />
                {deliveryAddress?.zip}
              </div>
            </div>
          )}
        </div>
        <div className="mt-8">
          <Button
            variant={ButtonVariant.Orange}
            onClick={() => {
              nextStep();
            }}
            className="w-full"
            disabled={isSubmittingOnboardStatus}
            loading={isSubmittingOnboardStatus}
          >
            <LockClosedIcon className="w-4 h-4 mr-1"></LockClosedIcon>
            Next
          </Button>
        </div>
        <div>
          <div className="flex items-center justify-center mt-1 text-xs text-center md:text-sm">
            <ShieldCheckIcon className="w-4 h-4 mr-1"></ShieldCheckIcon>
            We securely store and encrypt your personal information
          </div>
          <Button
            type={ButtonType.Button}
            variant={ButtonVariant.Transparent}
            className="pl-0"
            size={ButtonSize.Small}
            onClick={onPreviousStep}
          >
            &#60;&#60; Go back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ReviewUI;
