/** @format */

import { useLazyQuery } from "@apollo/client";
import {
  ENUM_CARD_SHARING,
  ENUM_DYNAMIC_PURCHASE_CONTROL_SETTING,
} from "@prisma/client";
import {
  READ_ACCOUNT_CONTROL_SETTINGS,
  READ_USER_ACTIVITY_LOGS,
} from "@roadflex/graphql";
import { Toast } from "@roadflex/react-components-www";
import { useReadUserDetails } from "@roadflex/react-hooks";
import {
  AccountSettingControlType,
  ChildFeatureType,
  UserActivityLogType,
  UserAuthType,
  UserDetailsType,
} from "@roadflex/types";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Loader } from "../../loader";
import { AccountSettingsUI } from "./account-settings.ui";

type AccountSettingsProps = {
  readCurrentUserAuth: UserAuthType;
  childFeatures: ChildFeatureType[];
  loading: boolean;
  refetch: () => void;
};

export const AccountSettings = ({
  readCurrentUserAuth,
  childFeatures,
  loading,
  refetch,
}: AccountSettingsProps) => {
  const { query } = useRouter();
  const [tabOpen, setTabOpen] = useState(1);

  const {
    readUserDetailsData,
    readUserDetailsLoading,
    readUserDetailsRefetch,
  } = useReadUserDetails("", "", "", "", ["BusinessDetails", "Setting"]);

  const [userDetails, setUserDetails] = useState<UserDetailsType | null>(null);
  const [userActivityLogsData, setUserActivityLogsData] = useState<
    UserActivityLogType[]
  >([]);
  const [accountControlSettingData, setAccountControlSettingData] =
    useState<AccountSettingControlType>({
      dynamicPurchaseControlsLinkedTo:
        ENUM_DYNAMIC_PURCHASE_CONTROL_SETTING.CARD,
      cardSharingControls: ENUM_CARD_SHARING.NONE,
    });

  useEffect(() => {
    if (query) {
      const { tab } = query;
      let element = null;
      if (tab === "1") {
        setTabOpen(1);
        return;
      }
      if (tab === "2") {
        setTabOpen(2);
        return;
      }
      if (tab) {
        element = document.querySelector(`#${tab}`);
      }
      if (element) {
        window.scrollTo({
          top: element.getBoundingClientRect().top + window.scrollY - 80,
          left: 0,
          behavior: "smooth",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    if (readUserDetailsData?.readUserDetails?.details && !loading) {
      setUserDetails(readUserDetailsData?.readUserDetails?.details);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readUserDetailsLoading]);

  const [userActivityLogsDataFn, { loading: userActivityLogsLoading }] =
    useLazyQuery(READ_USER_ACTIVITY_LOGS, {
      fetchPolicy: "no-cache",
      onCompleted: (data: {
        readUserActivityLogs: {
          userActivityLogs: UserActivityLogType[];
        };
      }) => {
        if (data) {
          setUserActivityLogsData(data.readUserActivityLogs?.userActivityLogs);
        }
      },
      onError: (err: Error) => {
        Toast({
          type: "error",
          message: `There is an error loading activity logs`,
        });
      },
      notifyOnNetworkStatusChange: true,
    });
  const [
    readAccountControlSettingFn,
    {
      refetch: accountControlSettingRefetch,
      loading: accountControlSettingLoading,
    },
  ] = useLazyQuery(READ_ACCOUNT_CONTROL_SETTINGS, {
    fetchPolicy: "no-cache",
    onCompleted: (data: {
      readAccountControlSettings: AccountSettingControlType;
    }) => {
      if (data) {
        setAccountControlSettingData(data?.readAccountControlSettings);
      }
    },
    onError: (err: Error) => {
      Toast({
        type: "error",
        message: `There is an error loading activity logs`,
      });
    },
    notifyOnNetworkStatusChange: true,
  });

  const onHandleTabChange = (tabId: number) => {
    switch (tabId) {
      case 3: {
        userActivityLogsDataFn();
        break;
      }
      case 4: {
        readAccountControlSettingFn();
        break;
      }
      default: {
        break;
      }
    }
  };

  if (loading || !userDetails) {
    return <Loader />;
  }
  return (
    <AccountSettingsUI
      {...{
        readCurrentUserAuth,
        readUserDetailsData: userDetails,
        childFeatures,
        tabOpen,
        setTabOpen,
        onHandleTabChange,
        userActivityLogsData,
        userActivityLogsLoading,
        accountControlSettingData,
        accountControlSettingLoading,
        accountControlSettingRefetch,
      }}
    />
  );
};
