import {
  AccountSettingDisplayType,
  UserActivityLogType,
} from "@roadflex/types";
import ActivityLogsUI from "./activity-logs.ui";

type ActivityLogsProps = {
  readAccountDisplaySetting: AccountSettingDisplayType;
  userActivityLogsData: UserActivityLogType[];
  userActivityLogsLoading: boolean;
};

const ActivityLogs = ({
  readAccountDisplaySetting,
  userActivityLogsData,
  userActivityLogsLoading,
}: ActivityLogsProps) => {
  return (
    <ActivityLogsUI
      userActivityLogsLoading={userActivityLogsLoading}
      userActivityLogsData={userActivityLogsData || []}
      readAccountDisplaySetting={readAccountDisplaySetting}
    />
  );
};
export default ActivityLogs;
