import { Loader } from "@roadflex/react-components-www";
import {
  AccountSettingControlType,
  AccountSettingDisplayType,
  FeatureType,
} from "@roadflex/types";
import ControlCenterUI from "./control-center.ui";

type ControlCenterProps = {
  readAccountDisplaySetting: AccountSettingDisplayType;
  controlCenterFeatureAccess: FeatureType[];
  accountControlSettingData: AccountSettingControlType;
  accountControlSettingLoading: boolean;
  accountControlSettingRefetch: () => void;
};

const ControlCenter = ({
  readAccountDisplaySetting,
  controlCenterFeatureAccess,
  accountControlSettingData,
  accountControlSettingLoading,
  accountControlSettingRefetch,
}: ControlCenterProps) => {
  if (accountControlSettingLoading || !accountControlSettingData) {
    return <Loader></Loader>;
  }
  return (
    <ControlCenterUI
      readAccountDisplaySetting={readAccountDisplaySetting}
      dynamicPurchaseControlsLinkedTo={
        accountControlSettingData?.dynamicPurchaseControlsLinkedTo
      }
      cardSharingControls={accountControlSettingData?.cardSharingControls}
      controlCenterFeatureAccess={controlCenterFeatureAccess}
      accountControlSettingRefetch={accountControlSettingRefetch}
    />
  );
};
export default ControlCenter;
