/** @format */

import {
  ENUM_CARD_SHARING,
  ENUM_DYNAMIC_PURCHASE_CONTROL_SETTING,
} from "@prisma/client";
import { AccountSettingDisplayType, FeatureType } from "@roadflex/types";
import AccountControls from "./account-controls/account-controls";
import DisplaySettings from "./display-settings/display-settings";

type ControlCenterUIProps = {
  readAccountDisplaySetting: AccountSettingDisplayType;
  dynamicPurchaseControlsLinkedTo: ENUM_DYNAMIC_PURCHASE_CONTROL_SETTING;
  cardSharingControls: ENUM_CARD_SHARING;
  controlCenterFeatureAccess: FeatureType[];
  accountControlSettingRefetch: () => void;
};

const ControlCenterUI = ({
  readAccountDisplaySetting,
  dynamicPurchaseControlsLinkedTo,
  cardSharingControls,
  controlCenterFeatureAccess,
  accountControlSettingRefetch,
}: ControlCenterUIProps) => {
  return (
    <div className="space-y-4">
      <DisplaySettings readAccountDisplaySetting={readAccountDisplaySetting} />
      <AccountControls
        dynamicPurchaseControlsLinkedTo={dynamicPurchaseControlsLinkedTo}
        cardSharingControls={cardSharingControls}
        controlCenterFeatureAccess={controlCenterFeatureAccess}
        accountControlSettingRefetch={accountControlSettingRefetch}
      ></AccountControls>
    </div>
  );
};
export default ControlCenterUI;
