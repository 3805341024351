/** @format */

import {
  ENUM_CARD_SHARING,
  ENUM_DYNAMIC_PURCHASE_CONTROL_SETTING,
} from "@prisma/client";
import { AccountSettingControlType, FeatureType } from "@roadflex/types";
import { humanizeEnum } from "@roadflex/utils";
import { FormikProps } from "formik";
import { isEmpty } from "lodash";
import { useState } from "react";
import { ConfirmModal } from "../../../../../../../libs/react-components/src/modals/confirm-modal/confirm-modal";
import { FuelCardComponentPermissionGuard } from "../../../../../../../libs/react-components/src/permission-guard";
import { Button, ButtonSize, ButtonVariant } from "../../../../buttons";
import { Select } from "../../../../dropdowns";
import { Toast } from "../../../../toast-message/toast";

type AccountControlsUIProps = FormikProps<AccountSettingControlType> & {
  controlCenterFeatureAccess: FeatureType[];
};

const AccountControlsUI = ({
  values,
  handleChange,
  handleSubmit,
  isSubmitting,
  handleBlur,
  errors,
  touched,
  setFieldValue,
  handleReset,
  initialValues,
  controlCenterFeatureAccess,
  ...rest
}: AccountControlsUIProps) => {
  const [update, setupdate] = useState(false);
  const [showConfirmation, setConfirmation] = useState(false);
  const toggle = () => setupdate((t) => !t);
  const discardChanges = () => {
    handleReset();
    setupdate(false);
  };
  const submitAction = () => {
    // e.preventDefault();
    if (!isEmpty(errors)) {
      Toast({
        type: "error",
        message: "Validation failed, please check your inputs",
      });
      return;
    }
    handleSubmit();
    toggle();
  };

  const hasAccess =
    controlCenterFeatureAccess.find(
      (item) =>
        item.name === "ACCOUNT_SETTING_PAGE_CONTROL_CENTER_ACCOUNT_SETTINGS",
    ) !== undefined;

  return (
    <>
      <form className="flex flex-col w-full text-sm md:text-base">
        <div>
          <div className="p-4 space-y-4 bg-white rounded-md shadow md:p-8">
            <div className="font-semibold md:py-2 text-brown-500 md:text-lg">
              Account Controls{" "}
              <span className="font-normal text-yellow-600">(Enterprise)</span>
            </div>
            <div className="flex flex-col justify-between sm:flex-row sm:items-center">
              <label htmlFor="fleet-type" className="block font-medium">
                Dynamic Purchase Controls
              </label>

              <div className="relative w-full sm:max-w-[250px] lg:max-w-2xl sm:ml-4">
                <Select
                  disabled={!update}
                  onChange={(e) =>
                    setFieldValue("dynamicPurchaseControlsLinkedTo", e.value)
                  }
                  value={values.dynamicPurchaseControlsLinkedTo}
                  name="dynamicPurchaseControlsLinkedTo"
                  options={Object.keys(
                    ENUM_DYNAMIC_PURCHASE_CONTROL_SETTING,
                  ).map((key) => {
                    return {
                      label: humanizeEnum(key),
                      value: key,
                      disabled:
                        key === ENUM_DYNAMIC_PURCHASE_CONTROL_SETTING.CARD
                          ? false
                          : true,
                    };
                  })}
                ></Select>
              </div>
            </div>
            <div className="flex flex-col justify-between sm:flex-row sm:items-center">
              <label htmlFor="fleet-type" className="block font-medium">
                Vehicle Sharing
              </label>

              <div className="relative w-full sm:max-w-[250px] lg:max-w-2xl sm:ml-4">
                <Select
                  disabled={!update}
                  onChange={(e) =>
                    setFieldValue("cardSharingControls", e.value)
                  }
                  value={values.cardSharingControls}
                  name="cardSharingControls"
                  options={Object.keys(ENUM_CARD_SHARING).map((key) => {
                    return {
                      label: humanizeEnum(key),
                      value: key,
                    };
                  })}
                ></Select>
              </div>
            </div>
            <div className="flex justify-end">
              {!update && (
                <FuelCardComponentPermissionGuard
                  type="button"
                  featurePermission={hasAccess}
                  messageType="enterprise"
                >
                  <Button
                    onClick={toggle}
                    variant={ButtonVariant.AppOrange}
                    size={ButtonSize.AppSize}
                    disabled={isSubmitting}
                  >
                    Update
                  </Button>
                </FuelCardComponentPermissionGuard>
              )}
              {update && (
                <div className="flex gap-x-2">
                  <Button
                    onClick={discardChanges}
                    variant={ButtonVariant.Red}
                    size={ButtonSize.AppSize}
                    disabled={isSubmitting}
                  >
                    Cancel
                  </Button>
                  <FuelCardComponentPermissionGuard
                    type="button"
                    featurePermission={hasAccess}
                    messageType="enterprise"
                  >
                    <Button
                      // type={ButtonType.Submit}
                      onClick={() => {
                        if (
                          initialValues.cardSharingControls !==
                          values.cardSharingControls
                        ) {
                          setConfirmation(true);
                        } else {
                          submitAction();
                        }
                      }}
                      variant={ButtonVariant.Primary}
                      size={ButtonSize.AppSize}
                      loading={isSubmitting}
                      disabled={isSubmitting || Object.keys(errors).length > 0}
                    >
                      Save
                    </Button>
                  </FuelCardComponentPermissionGuard>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
      {showConfirmation && (
        <ConfirmModal
          title={"Confirm updating account control settings"}
          body={
            <div className="font-medium">
              <div className="">
                <div className="mb-3">
                  {values.cardSharingControls ===
                  ENUM_CARD_SHARING.VEHICLE_SHARING
                    ? "Vehicle sharing involves drivers sharing vehicles, with one card assigned to each vehicle. Once enabled, drivers will be unassigned from all cards and only assigned vehicles will be able to use the cards. Confirm to proceed."
                    : "Updating sharing controls to 'None' means that drivers can now be assigned to cards. Confirm to proceed."}
                </div>
              </div>
            </div>
          }
          setShow={setConfirmation}
          show={showConfirmation}
          confirm={submitAction}
          submitting={isSubmitting}
        ></ConfirmModal>
      )}
    </>
  );
};
export default AccountControlsUI;
