/** @format */
import { PlaidType } from "@roadflex/types";
import {
  FunctionComponent,
  MutableRefObject,
  useCallback,
  useState,
} from "react";
import {
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  usePlaidLink,
} from "react-plaid-link";

interface Props {
  linkToken: string;
  institution: MutableRefObject<string>;
  publicToken: MutableRefObject<string>;
  setStatus: (status: unknown) => void;
  setFieldValue: (field: string, value: boolean) => void;
  plaidData: PlaidType[];
}

const PlaidLink: FunctionComponent<Props> = ({
  linkToken,
  institution,
  publicToken,
  setStatus,
  setFieldValue,
  plaidData,
}) => {
  const [name, setName] = useState(institution.current);
  const onSuccess = useCallback<PlaidLinkOnSuccess>((token, metadata) => {
    // @TODO: @rohan remove eslint-diable and fix the issue
    // eslint-disable-next-line no-param-reassign
    institution.current =
      metadata?.institution?.name || metadata.accounts[0].name;
    // eslint-disable-next-line no-param-reassign
    publicToken.current = token || "";
    setName(institution.current);
    setStatus({ error: null });
    setFieldValue("plaidConnect", true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const config: PlaidLinkOptions = {
    token: linkToken,
    onSuccess,
    // onExit
    // onEvent
  };

  const { open, ready } = usePlaidLink(config);
  return (
    <div className="">
      {name === "" && plaidData.length === 0 ? (
        <button
          type="button"
          className="w-full h-12 text-lg bg-[#76341D] border-opacity-50 rounded text-white"
          onClick={() => open()}
          disabled={!ready}
        >
          Link Business Bank Account
        </button>
      ) : (
        <p className="text-sm text-black">
          You have successfully connected your{" "}
          <strong>{name || plaidData[0]?.institution}</strong> bank account.
          Please click on Review Application button to review your application.
        </p>
      )}
    </div>
  );
};

export default PlaidLink;
